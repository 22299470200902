import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, Button, Link, LoadingSpinner, SelectInput } from 'shared-library';
import { Row, Column } from '../Layout';
import { Opportunity } from '../../types';

const Actions = styled(Column)`
  margin: 32px auto 16px;
  text-align: center;
`;

const CancelButton = styled(Link).attrs({
  as: 'button',
})`
  background-color: transparent;
  border: 0;
  margin: 0 20px 10px 0;
`;

export type ContactInformationInput = {
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: {
    label: string;
    value: string;
  };
  phoneNumber: string;
};

interface ContactInformationFormProps {
  isSaving: boolean;
  onCancelEdit: () => void;
  onSubmit;
  opportunity: Opportunity;
  fields;
}

const BookingForm = ({
  isSaving,
  onCancelEdit,
  onSubmit,
  opportunity,
  fields,
}: ContactInformationFormProps): React.ReactElement => {
  const sourcesOptions = fields.data.sources
    ? fields.data.sources.map((s) => {
        return { value: s.id, label: s.name };
      })
    : [];

  const typesOptions = fields.data.types
    ? fields.data.types.map((s) => {
        return { value: s.id, label: s.name };
      })
    : [];

  const [type, setType] = useState(
    typesOptions.find((_type: any) => _type.value === opportunity.Type),
  );
  const [leadSource, setLeadSource] = useState(
    sourcesOptions.find((source) => source.value === opportunity.LeadSource),
  );

  const onTypeChange = (value) => setType(value);
  const onSourceChange = (value) => setLeadSource(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      type,
      leadSource,
    };
    onSubmit(data);
  };

  return (
    <>
      <Row>
        <Column>
          <SelectInput
            name="type"
            label="Type of member"
            fillWidth
            placeholder="Please select"
            onChange={onTypeChange}
            options={typesOptions}
            value={type}
          />
        </Column>
        <Column>
          <SelectInput
            name="leadSource"
            label="Lead acquisition source"
            fillWidth
            placeholder="Please select"
            onChange={onSourceChange}
            options={sourcesOptions}
            value={leadSource}
          />
        </Column>
      </Row>
      <Row>
        <Actions>
          <>
            {onCancelEdit && <CancelButton onClick={onCancelEdit} size="small">Cancel</CancelButton>}
            <Button onClick={handleSubmit} disabled={isSaving} type="submit" variant="primary" size="small">
              {isSaving ? <LoadingSpinner color={colors.white} size="small" /> : 'Save'}
            </Button>
          </>
        </Actions>
      </Row>
    </>
  );
};

export default BookingForm;
