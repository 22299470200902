import { getMomentFromUnixTimestamp } from 'packages/formatters';
import React from 'react';
import { Section, colors, LoadingSpinner, Text, Card, Heading, Link } from 'shared-library';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { buildStudySelector } from '../../store/studies/selectors';
import { Column, Label, Row } from '../Layout';

const FeedbackSection = ({ handleActionDispatch, actionsProcessing, actionsAvailable }) => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);

  return (
    <Section
      disabled={!study.status.releasedToPatient}
      label="7"
      title="Obtain feedback"
      viewState="show"
    >
      {!study.status.releasedToPatient && (
        <Text>
          This action is available once the radiology report has been released to the member.
        </Text>
      )}
      {study.status.releasedToPatient && (
        <>
          {study.status.feedbackReceived && (
            <Card backgroundColor={colors.smoke}>
              <Heading color={colors.primary} size={5}>
                Completed
              </Heading>
              <Text>
                The member gave us feedback on{' '}
                {getMomentFromUnixTimestamp(study.status.feedbackReceivedTime).format(
                  'DD MMM YYYY',
                )}{' '}
                at {getMomentFromUnixTimestamp(study.status.feedbackReceivedTime).format('hh:mma')}.
              </Text>
            </Card>
          )}
          {!study.status.feedbackReceived && (
            <>
              <Row>
                <Text>We have not yet received feedback from this member.</Text>
              </Row>
              {!study.status.feedbackReminderSentTime && (
                <Row>
                  <Text>Feedback reminder never requested.</Text>
                </Row>
              )}
              <Row>
                <Column>
                  <Label>SMS</Label>
                  <div>
                    <Link
                      title="Test"
                      onClick={() => handleActionDispatch('feedbackReminderViaSMS')}
                      disabled={actionsProcessing.feedbackReminderViaSMS || !actionsAvailable.sms}
                    >
                      <>
                        {actionsProcessing.feedbackReminderViaSMS && (
                          <LoadingSpinner color={colors.white} size="small" />
                        )}
                        Feedback reminder
                      </>
                    </Link>
                  </div>
                  {study.status.feedbackReminderSMSSentTime && (
                    <div>
                      Last:{' '}
                      {getMomentFromUnixTimestamp(study.status.feedbackReminderSMSSentTime).format(
                        'DD MMM YYYY',
                      )}
                    </div>
                  )}
                </Column>
                <Column>
                  <Label>Email</Label>
                  <div>
                    <Link
                      onClick={() => handleActionDispatch('feedbackReminderViaEmail')}
                      disabled={actionsProcessing.feedbackReminderViaEmail}
                    >
                      Feedback reminder
                    </Link>
                  </div>
                  {study.status.feedbackReminderEmailSentTime && (
                    <div>
                      Last:{' '}
                      {getMomentFromUnixTimestamp(
                        study.status.feedbackReminderEmailSentTime,
                      ).format('DD MMM YYYY')}
                    </div>
                  )}
                </Column>
              </Row>
            </>
          )}
        </>
      )}
    </Section>
  );
};

export default FeedbackSection;
