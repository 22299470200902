import React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getMomentFromUnixTimestamp } from 'packages/formatters';
import { Section, colors, Button, LoadingSpinner, Text, Heading, Link, Card } from 'shared-library';
import ScheduleConsult from '../StudyDetails/ScheduleConsult';
import { Column, Label, Row } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';
import SendStudyTracking from '../StudyDetails/SendStudyTracking';

const PostReleaseContact = ({ handleActionDispatch, actionsProcessing, actionsAvailable }) => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const staff = useSelector(selectStaff);

  const patientConsultRequestedBy = study.practitioners?.consulting?.requestedBy
    ? staff.data.filter(
        (member) => member.userId === study.practitioners?.consulting?.requestedBy,
      )[0]
    : null;

  const patientReportNotificationBy = study.status.patientReportNotificationBy
    ? staff.data.filter((member) => member.userId === study.status.patientReportNotificationBy)[0]
    : null;

  const providerReportNotificationBy = study.status.providerReportNotificationdBy
    ? staff.data.filter((member) => member.userId === study.status.providerReportNotificationdBy)[0]
    : null;

  let sensitiveUrgentCopy = 'This report is not sensitive or urgent.';
  if (study.status.sensitive && study.status.urgent) {
    sensitiveUrgentCopy = 'This report has been marked as sensitive and urgent.';
  } else if (study.status.sensitive && !study.status.urgent) {
    sensitiveUrgentCopy = 'This report has been marked as sensitive.';
  } else if (!study.status.sensitive && study.status.urgent) {
    sensitiveUrgentCopy = 'This report has been marked as urgent.';
  }

  const shouldDisplayScheduleConsult =
    study.practitioners?.consulting?.requestedStatus === 'requested' ||
    !!study.practitioners?.consulting?.scheduledStatus;

  return (
    <Section label="6" title="Make contact upon report being released" viewState="show">
      <>
        {!study.status.published && (
          <>
            <Row>
              <Text>The study is not yet published.</Text>
            </Row>
            <Row>
              <Text>{sensitiveUrgentCopy}</Text>
            </Row>
          </>
        )}

        <Row>
          <Heading size={7}>Patient consult</Heading>
        </Row>
        {(study.practitioners?.consulting?.requestedStatus === 'requested' ||
          study.practitioners?.consulting?.requestedStatus === 'not requested') && (
          <Row>
            <Text>
              The patient told {patientConsultRequestedBy?.firstname}{' '}
              {patientConsultRequestedBy?.lastname} that they
              {study.practitioners?.consulting?.requestedStatus === 'requested'
                ? ' required '
                : ' did not require '}
              a consult at{' '}
              {getMomentFromUnixTimestamp(
                study.practitioners?.consulting?.requestedAt,
                study.location.timezone,
              ).format('DD MMM YYYY')}{' '}
              at{' '}
              {getMomentFromUnixTimestamp(
                study.practitioners?.consulting?.requestedAt,
                study.location.timezone,
              ).format('hh:mma')}
              .
            </Text>
          </Row>
        )}

        {(!study.practitioners?.consulting?.requestedStatus ||
          study.practitioners?.consulting?.requestedStatus === 'pending') && (
          <>
            <Row>
              <Text>
                Please ask the member if they would like a consult with a member of our independent
                medical staff.
              </Text>
            </Row>
            <Row>
              <Column>
                <Button
                  onClick={() => handleActionDispatch('patientConsultRequested')}
                  disabled={actionsProcessing.patientConsultRequested}
                  size="medium"
                  variant="primary"
                >
                  <>
                    {actionsProcessing.patientConsultRequested && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Requested consult
                  </>
                </Button>
              </Column>
              <Column>
                <Button
                  onClick={() => handleActionDispatch('patientConsultNotRequested')}
                  disabled={actionsProcessing.patientConsultNotRequested}
                  size="medium"
                  variant="secondary"
                >
                  <>
                    {actionsProcessing.patientConsultNotRequested && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    No consult required
                  </>
                </Button>
              </Column>
            </Row>
          </>
        )}

        <Row>
          <Heading size={7}>Patient notification</Heading>
        </Row>
        {!study.status.releasedToPatient && (
          <Row>
            <Text>This report has not been released to the patient.</Text>
          </Row>
        )}

        {study.status.published && (
          <>
            {study.status.patientReportNotificationBy && (
              <Row>
                <Text>
                  The patient was informed of their report being available by{' '}
                  {patientReportNotificationBy?.firstname} {patientReportNotificationBy?.lastname}{' '}
                  at{' '}
                  {getMomentFromUnixTimestamp(
                    study.status.patientReportNotificationTime,
                    study.location.timezone,
                  ).format('DD MMM YYYY')}{' '}
                  {getMomentFromUnixTimestamp(
                    study.status.patientReportNotificationTime,
                    study.location.timezone,
                  ).format('hh:mma')}
                  .
                </Text>
              </Row>
            )}
            {!study.status.patientReportNotificationBy && (
              <>
                <Row>
                  <Text>
                    Please contact the member to let them know that their report is ready.
                  </Text>
                </Row>
                <Row>
                  <Column>
                    <Label>SMS</Label>

                    <div>
                      <Link
                        title="Test"
                        onClick={() =>
                          handleActionDispatch('reportReleasedViaSmsNotSensitiveWithPrimaryCare')
                        }
                        disabled={
                          actionsProcessing.reportReleasedViaSmsNotSensitiveWithPrimaryCare ||
                          !actionsAvailable.sms
                        }
                      >
                        <>
                          {actionsProcessing.reportReleasedViaSmsNotSensitiveWithPrimaryCare && (
                            <LoadingSpinner color={colors.white} size="small" />
                          )}
                          Report released (not-sensitive, PCP)
                        </>
                      </Link>
                    </div>
                    <div>
                      <Link
                        title="Test"
                        onClick={() =>
                          handleActionDispatch('reportReleasedViaSmsNotSensitiveWithoutPrimaryCare')
                        }
                        disabled={
                          actionsProcessing.reportReleasedViaSmsNotSensitiveWithoutPrimaryCare ||
                          !actionsAvailable.sms
                        }
                      >
                        <>
                          {actionsProcessing.reportReleasedViaSmsNotSensitiveWithoutPrimaryCare && (
                            <LoadingSpinner color={colors.white} size="small" />
                          )}
                          Report released (not-sensitive, no PCP)
                        </>
                      </Link>
                    </div>
                  </Column>
                  <Column>
                    <Label>&nbsp;</Label>
                    <div>
                      <Link
                        title="Test"
                        onClick={() =>
                          handleActionDispatch('reportReleasedViaSmsSensitiveWithPrimaryCare')
                        }
                        disabled={
                          actionsProcessing.reportReleasedViaSmsSensitiveWithPrimaryCare ||
                          !actionsAvailable.sms
                        }
                      >
                        <>
                          {actionsProcessing.reportReleasedViaSmsSensitiveWithPrimaryCare && (
                            <LoadingSpinner color={colors.white} size="small" />
                          )}
                          Report released (sensitive, PCP)
                        </>
                      </Link>
                    </div>
                    <div>
                      <Link
                        title="Test"
                        onClick={() =>
                          handleActionDispatch('reportReleasedViaSmsSensitiveWithoutPrimaryCare')
                        }
                        disabled={
                          actionsProcessing.reportReleasedViaSmsSensitiveWithoutPrimaryCare ||
                          !actionsAvailable.sms
                        }
                      >
                        <>
                          {actionsProcessing.reportReleasedViaSmsSensitiveWithoutPrimaryCare && (
                            <LoadingSpinner color={colors.white} size="small" />
                          )}
                          Report released (sensitive, no PCP)
                        </>
                      </Link>
                    </div>
                  </Column>

                  <Column>
                    <Label>Telephone</Label>
                    <div>
                      <Link
                        onClick={() => {
                          handleActionDispatch('callRingOutPatient');
                        }}
                        disabled={actionsProcessing.reminderRingoutCall}
                      >
                        Call patient
                      </Link>
                    </div>
                    <Label>Email</Label>
                    <Text>
                      {actionsProcessing.resendConfirmation && <LoadingSpinner size="small" />}
                    </Text>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Button
                      onClick={() => handleActionDispatch('patientReportNotification')}
                      disabled={actionsProcessing.patientReportNotification}
                      size="medium"
                      variant="primary"
                    >
                      <>
                        {actionsProcessing.patientReportNotification && (
                          <LoadingSpinner color={colors.white} size="small" />
                        )}
                        Member contacted
                      </>
                    </Button>
                  </Column>
                </Row>
              </>
            )}
            {shouldDisplayScheduleConsult && (
              <ScheduleConsult shouldDisplayScheduleConsult={shouldDisplayScheduleConsult} />
            )}
          </>
        )}
        <Row>
          <Heading size={7}>Providers</Heading>
        </Row>
        <Row>
          <SendStudyTracking />
        </Row>
        {study.status.published && study.status.providerAdded && (
          <>
            <Row>
              <>
                {!study.status.providerReportNotification && (
                  <Column>
                    <Button
                      onClick={() => handleActionDispatch('providerReportNotification')}
                      disabled={actionsProcessing.providerReportNotification}
                      size="medium"
                      variant="primary"
                    >
                      <>
                        {actionsProcessing.providerReportNotification && (
                          <LoadingSpinner color={colors.white} size="small" />
                        )}
                        Provider(s) notified
                      </>
                    </Button>
                  </Column>
                )}
                {study.status.providerReportNotification && (
                  <Card backgroundColor={colors.smoke}>
                    <Heading color={colors.primary} size={5}>
                      Completed
                    </Heading>
                    <Text>
                      Provider notification was completed by{' '}
                      {providerReportNotificationBy?.firstname}{' '}
                      {providerReportNotificationBy?.lastname} on{' '}
                      {getMomentFromUnixTimestamp(
                        study.status.providerReportNotificationTime,
                      ).format('DD MMM YYYY')}{' '}
                      at{' '}
                      {getMomentFromUnixTimestamp(
                        study.status.providerReportNotificationTime,
                      ).format('hh:mma')}
                      .
                    </Text>
                  </Card>
                )}
              </>
            </Row>
          </>
        )}
        {study.status.published && !study.status.providerAdded && (
          <Row>
            <Text>There are no providers for this study.</Text>
          </Row>
        )}
        {!study.status.published && (
          <Row>
            <Text>The study has not been published yet.</Text>
          </Row>
        )}
      </>
    </Section>
  );
};

export default PostReleaseContact;
