import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import {
  Notification,
  Section,
  colors,
  Button,
  LoadingSpinner,
  Text,
  Card,
  Heading,
  Link,
} from 'shared-library';
import { getMomentFromUnixTimestamp } from 'packages/formatters';

import { fetchAppsResource } from 'packages/apis';
import { Column, Label, Row } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { captureError } from '../../lib';
import { buildPatientSelector } from '../../store/patients/selectors';
import { selectStaff } from '../../store/staff/selectors';

{
  /* eslint-disable jsx-a11y/anchor-is-valid */
}

const RemindAppointment = ({
  handleActionDispatch,
  actionsProcessing,
  setActionProcessing,
  actionsAvailable,
  formattedPatient,
}) => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));
  const { addToast } = useToasts();
  const staff = useSelector(selectStaff);

  const remindedBy = study.status.remindedBy
    ? staff.data.filter((member) => member.userId === study.status.remindedBy)[0]
    : null;

  const handleResendConfirmationClick = async () => {
    setActionProcessing({ ...actionsProcessing, resendConfirmation: true });
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/email/booking-confirmation`);
      addToast(
        Notification.create(
          'Reconfirmation sent',
          `A reconfirmation email has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setActionProcessing({ ...actionsProcessing, resendConfirmation: false });
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot reconfirm an appointment for a past booking.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Reconfirmation failed', errorMessage), { appearance: 'error' });
      setActionProcessing({ ...actionsProcessing, resendConfirmation: false });
    }
  };

  return (
    <Section label="1" title="Remind member of appointment" viewState="show">
      {!study.status.reminded && (
        <>
          <Row>
            <Column>
              <Label>SMS</Label>
              <div>
                <Link
                  title="Test"
                  onClick={() => handleActionDispatch('appointmentReminderViaSms')}
                  disabled={actionsProcessing.appointmentReminderViaSms || !actionsAvailable.sms}
                >
                  <>
                    {actionsProcessing.appointmentReminderViaSms && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Appointment reminder
                  </>
                </Link>
              </div>
              <div>
                <Link
                  onClick={() => handleActionDispatch('googleMapsClinicLinkViaSms')}
                  disabled={actionsProcessing.googleMapsClinicLinkViaSms || !actionsAvailable.sms}
                >
                  <>
                    {actionsProcessing.appointmentReminderViaSms && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Maps link of clinic
                  </>
                </Link>
              </div>
            </Column>
            <Column>
              <Label>Telephone</Label>
              <div>
                <Link
                  onClick={() => {
                    handleActionDispatch('callRingOutPatient');
                  }}
                  disabled={actionsProcessing.reminderRingoutCall }
                >
                  Call member
                </Link>
              </div>
              <div>
                <Link
                  onClick={() => {
                    handleActionDispatch('testFax');
                  }}
                  disabled={actionsProcessing.reminderRingoutCall }
                >
                  Test fax (do not use)
                </Link>
              </div>
            </Column>
            <Column>
              <Label>Email</Label>
              <Text>
                {!actionsProcessing.resendConfirmation && (
                  <Link as="button" onClick={() => handleResendConfirmationClick()}>
                    Resend booking confirmation
                  </Link>
                )}
                {actionsProcessing.resendConfirmation && <LoadingSpinner size="small" />}
              </Text>
            </Column>
          </Row>
          <Row>
            <Button
              onClick={() => handleActionDispatch('reminded')}
              disabled={actionsProcessing.reminded}
              size="medium"
              variant="primary"
            >
              <>
                {actionsProcessing.reminded && <LoadingSpinner color={colors.white} size="small" />}
                Member reminded
              </>
            </Button>
          </Row>
        </>
      )}
      {study.status.reminded && (
        <Row>
          <Card backgroundColor={colors.smoke}>
            <Heading color={colors.primary} size={5}>
              Completed
            </Heading>
            <Text>
              {formattedPatient?.firstname} was reminded by{' '}
              {remindedBy ? remindedBy?.firstname : '---'}{' '}
              {remindedBy ? remindedBy?.lastname : '---'} on{' '}
              {getMomentFromUnixTimestamp(
                study.status.remindedTime,
                study.location.timezone,
              ).format('DD MMM YYYY')}{' '}
              at{' '}
              {getMomentFromUnixTimestamp(
                study.status.remindedTime,
                study.location.timezone,
              ).format('hh:mma')}
              .
            </Text>
          </Card>
        </Row>
      )}
    </Section>
  );
};
export default RemindAppointment;
