import { getEnvironment, getHpdUrl } from 'packages/locations';
import { fetchHiResource } from 'packages/apis';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Text,
  Modal,
  Label,
  Button,
  CheckboxInput,
  utils,
  colors,
  PDF,
  LoadingLogo,
  Notification,
  LoadingSpinner,
} from 'shared-library';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Patient } from '../../store/patients/types';
import { StudyState } from '../../store/studies/types';
import { Practitioner, FollowUpType } from './SendStudyTracking';
import { addLogItem } from '../../store/studies/actions';
import { selectSession } from '../../store/session/selectors';

const ModalRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  justify-content: start;
  align-items: flex-start;
  margin: 20px 0 10px 0;
`;

const ButtonRow = styled.div<{ hasHiPermission: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ hasHiPermission }) => (hasHiPermission ? 'space-between' : 'flex-end')};
`;

const ModalCol = styled.div`
  flex: 1;
`;

const StyledCheckboxInput = styled(CheckboxInput)`
  margin-bottom: 30px;
`;

const ReportButton = styled(Button)`
  margin-right: 20px;
`;

const ErrorText = styled(Text).attrs({
  color: colors.error,
  weight: '600',
})`
  font-size: 12px;
  margin-bottom: 20px;
`;

const PractitionerLink = styled.a`
  padding: 0 5px;
`;

const ReportWrapper = styled.div`
  margin-top: 30px;

  .react-pdf__Document {
    height: 900px;
    overflow-y: scroll;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

const Loading = styled(LoadingSpinner)`
  position: relative;
  margin-left: 10px;
`;

type AutomatedFollowUpModalProps = {
  action: FollowUpType;
  study: StudyState;
  recipient: Practitioner;
  patient: Patient;
  senderName: string;
  onModalClose: () => void;
  addTrackingLog: (
    categoryName: string,
    practitioner: Practitioner,
    displayToast?: boolean,
  ) => Promise<void>;
  practitionerIds: Record<string, string>[];
};

const ReportPDFPreview = ({
  pdf,
  pdfName,
  loading,
}: {
  pdf: string;
  pdfName: string;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <LoadingWrapper>
        <LoadingLogo />
      </LoadingWrapper>
    );
  }

  return (
    <ReportWrapper>
      <PDF
        pdf={pdf}
        title={pdfName}
        renderTextLayer={false}
        showPageButtons={false}
        showPrintButton={false}
        renderMode="canvas"
        renderImageContent
      />
    </ReportWrapper>
  );
};

const isValidEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(String(email).toLowerCase());
};

const AutomatedFollowUpModal = ({
  action,
  study,
  recipient,
  patient,
  senderName,
  onModalClose,
  addTrackingLog,
  practitionerIds,
}: AutomatedFollowUpModalProps): ReactElement => {
  const { addToast } = useToasts();
  const { data: session } = useSelector(selectSession);
  // TODO: Remove env check after implementing the new Auth0 env
  const isNonProdEnvironment = getEnvironment() !== 'PRODUCTION';
  const hasHiPermission = session.roles.includes('hi') || isNonProdEnvironment;

  const [previewPDF, setPreviewPDF] = useState(false);
  const [infoConfirmed, setInfoConfirmed] = useState(false);
  const [pdf, setPdf] = useState<string | null>(null);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [reportButton, setReportButton] = useState('Preview Report');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const recipientLocationId =
    practitionerIds.find((item) => item.id === recipient.id)?.locationId || null;
  const recipientLocation = recipient.locations.edges.find(
    (location) => location.node?.id === recipientLocationId,
  )?.node;
  const practiceName = recipientLocation?.practice?.practiceName;
  const locationName = recipientLocation?.locationName;

  const getRecipientFaxNumber = () => {
    if (recipientLocation?.practitionerContact?.faxNumber) {
      return recipientLocation.practitionerContact.faxNumber;
    }

    if (recipientLocation?.contact?.faxNumber) {
      return recipientLocation.contact?.faxNumber;
    }

    if (recipient.contact.faxNumber) {
      return recipient.contact.faxNumber;
    }

    return null;
  };

  const recipientFaxNumber = action === 'fax' ? getRecipientFaxNumber() : null;
  const cleanFaxNumber = recipientFaxNumber ? recipientFaxNumber.match(/\d/g)?.join('') : null;

  let validFollowUpContact = false;

  if (action === 'fax') {
    validFollowUpContact = cleanFaxNumber ? cleanFaxNumber.length === 11 : false; // third party API limitation
  }

  if (action === 'email') {
    validFollowUpContact = isValidEmail(recipient.contact.emailAddress);
  }

  const pdfName = `Prenuvo MRI Report_${patient.firstname} ${patient.lastname[0]}.pdf`;
  const disableAutomatedButton = !infoConfirmed || !validFollowUpContact || sendLoading;

  const fetchPdf = async (isManualDownload = false) => {
    try {
      setFetchLoading(true);

      const { parsedBody } = await fetchHiResource(
        `/api/v1/study/${study.studyID}/pdf/preview`,
        'POST',
        {
          memberDetails: {
            firstname: patient.firstname,
            lastname: patient.lastname,
            dob: patient.dob,
            sex: patient.gender,
          },
          shouldEncryptPdf: isManualDownload,
          shouldHideHIContent: !isManualDownload,
        },
      );

      if ((parsedBody as Record<string, string>)?.pdf) {
        const { pdf: pdfBase64 } = parsedBody as Record<string, string>;
        const pdfData = isManualDownload
          ? `data:application/pdf;base64,${pdfBase64}`
          : `data:image/png;base64,${pdfBase64}`;
        if (!isManualDownload) {
          setPdf(pdfData);
        }
        return pdfData;
      }
      return null;
      // eslint-disable-next-line no-useless-catch
    } catch (event) {
      addToast(
        Notification.create(
          'Error fetching report',
          'Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      throw event;
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    if (!pdf) {
      fetchPdf();
    }
  }, [pdf]);

  useEffect(() => {
    if (!pdf) {
      setReportButton('Loading Report');
    } else if (previewPDF) {
      setReportButton('Hide Preview');
    } else {
      setReportButton('Preview Report');
    }
  }, [previewPDF, pdf]);

  const onDownloadPDF = async () => {
    if (hasHiPermission) {
      const isEmailOrFaxDownload = action === 'email' || action === 'fax';
      setIsLoading(true);
      const category = 'General';
      const content = `Physician Report Downloaded`;
      const link = document.createElement('a');
      const downloadPdf = await fetchPdf(isEmailOrFaxDownload);
      link.href = downloadPdf;
      link.download = pdfName;
      link.click();
      try {
        await addLogItem(study.studyID, content, category)(dispatch);
      } catch (error) {
        addToast(Notification.create("Couldn't add log", 'Please report an issue'), {
          appearance: 'error',
        });
      } finally {
        setIsLoading(false);
      }
      addTrackingLog(`manual ${action}`, recipient);
      onModalClose();
    }
  };

  const onAutomatedFax = async () => {
    if (study.studyID && validFollowUpContact) {
      try {
        setSendLoading(true);
        await fetchHiResource(`/api/v1/study/${study.studyID}/fax/send`, 'POST', {
          practitioner: {
            firstname: recipient.firstName,
            lastname: recipient.lastName,
            email: recipient.contact.emailAddress || '',
            fax: cleanFaxNumber,
            id: recipient.id,
          },
          memberDetails: {
            firstname: patient.firstname,
            lastname: patient.lastname,
            dob: patient.dob,
            sex: patient.gender,
          },
          loggedinUser: senderName,
        });

        addToast(Notification.create('Automated fax has been requested', ''), {
          appearance: 'success',
        });
        addTrackingLog('automated fax', recipient, false);
      } catch (event) {
        addToast(
          Notification.create(
            'Error sending automated fax',
            'Refresh the page and look at Activity logs for an error message and report an issue if it persists.',
          ),
          { appearance: 'error' },
        );
        throw event;
      } finally {
        onModalClose();
        setSendLoading(false);
      }
    }
  };

  const onAutomatedEmail = async () => {
    if (study.studyID && validFollowUpContact) {
      try {
        setSendLoading(true);
        await fetchHiResource(`/api/v1/study/${study.studyID}/email_report/send`, 'POST', {
          practitioner: {
            salutation: recipient.salutation,
            firstname: recipient.firstName,
            lastname: recipient.lastName,
            email: recipient.contact.emailAddress || '',
            id: recipient.id,
            prenuvoId: recipient.prenuvoId,
          },
          memberDetails: {
            firstname: patient.firstname,
            lastname: patient.lastname,
            dob: patient.dob,
          },
          loggedinUser: senderName,
        });

        addToast(Notification.create('Email Confirmation', 'Email successfully sent'), {
          appearance: 'success',
        });
        addTrackingLog('automated email', recipient, false);
      } catch (event) {
        addToast(
          Notification.create(
            'Error sending automated email',
            'Refresh the page and look at Activity logs for an error message and report an issue if it persists.',
          ),
          { appearance: 'error' },
        );
        throw event;
      } finally {
        onModalClose();
        setSendLoading(false);
      }
    }
  };

  const onAutomatedSubmit = () => {
    if (action === 'fax') {
      onAutomatedFax();
    }

    if (action === 'email') {
      onAutomatedEmail();
    }
  };

  const onPreviewPDF = () => {
    setPreviewPDF(!previewPDF);
  };

  const linkToHPD = (
    <PractitionerLink
      href={`${getHpdUrl()}/practitioner/${recipient.id}`}
      target="_blank"
      rel="noreferrer"
    >
      click here
    </PractitionerLink>
  );

  return (
    <Modal title="Manual or Automated Selection" onClose={onModalClose}>
      <Text>
        Please Preview Report to confirm Member information is correct. Please confirm Recipient
        Information is correct. Automated will trigger the {action} to be sent
        {action === 'fax' && ', along with an automatic cover sheet'}
        {action === 'email' && ', with password protected report as attachment'}.
      </Text>
      <ModalRow>
        <ModalCol>
          <Label size="large">Member Information</Label>
          <Label>First Name</Label>
          <Text>{patient.firstname}</Text>
          <Label>Last Name</Label>
          <Text>{patient.lastname}</Text>
          <Label>Date Of Birth</Label>
          <Text>{patient.dob}</Text>
          <Label>Biological Sex</Label>
          <Text>{utils.capitalize(patient.gender)}</Text>
        </ModalCol>
        <ModalCol>
          <Label size="large">Recipient Information</Label>
          <Label>First Name</Label>
          <Text>{recipient.firstName}</Text>
          <Label>Last Name</Label>
          <Text>{recipient.lastName}</Text>
          <Label>Profession</Label>
          <Text>{recipient.profession}</Text>
          <Label>Practice/Location</Label>
          <Text>{`${practiceName || '-'} / ${locationName || '-'}`}</Text>
          {action === 'fax' && (
            <>
              <Label>Fax Number</Label>
              <Text>{recipientFaxNumber || '-'}</Text>
            </>
          )}
          {action === 'email' && (
            <>
              <Label>Email Address</Label>
              <Text>{recipient.contact?.emailAddress || '-'}</Text>
            </>
          )}
        </ModalCol>
      </ModalRow>
      {action === 'fax' && !validFollowUpContact && (
        <ErrorText>
          Recipient Fax Number is not valid. Ensure it is 11 digits. Please {linkToHPD} to update
          the recipient information. You will be taken to the Health Practitioners Directory (HPD)
          in a new tab. Once updated, come back and refresh this page to continue your automated
          fax.
        </ErrorText>
      )}
      {action === 'email' && !validFollowUpContact && (
        <ErrorText>
          Recipient Email Address is not valid. Please {linkToHPD} to update the recipient
          information. You will be taken to the Health Practitioners Directory (HPD) in a new tab.
          Once updated, come back and refresh this page to continue your automated email.
        </ErrorText>
      )}
      <StyledCheckboxInput
        id="modal-confirm"
        onChange={() => setInfoConfirmed(!infoConfirmed)}
        checked={infoConfirmed}
        label="I confirm the member and recipient information above are correct."
      />
      <ButtonRow hasHiPermission={hasHiPermission}>
        {hasHiPermission ? (
          <Button
            onClick={onDownloadPDF}
            size="medium"
            variant="secondary"
            disabled={!infoConfirmed || !pdf || sendLoading}
          >
            <ButtonWrapper>
              Manual
              {isLoading && <Loading color={colors.primary} size="small" />}
            </ButtonWrapper>
          </Button>
        ) : null}
        <div>
          <ReportButton onClick={onPreviewPDF} size="medium" variant="secondary" disabled={!pdf}>
            {reportButton}
          </ReportButton>
          <Button
            onClick={onAutomatedSubmit}
            size="medium"
            variant="secondary"
            disabled={disableAutomatedButton}
          >
            {sendLoading ? 'Sending' : 'Automated'}
          </Button>
        </div>
      </ButtonRow>
      {previewPDF && <ReportPDFPreview pdf={pdf} pdfName={pdfName} loading={fetchLoading} />}
    </Modal>
  );
};

export default AutomatedFollowUpModal;
