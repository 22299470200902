import React from 'react';
import { Button as SLButton, Modal, Text } from 'shared-library';
import styled from 'styled-components';

export const Div = styled.div`
  justify-content: space-around;
  display: flex;
`;

export const Button = styled(SLButton)`
  width: 300px;
`;

interface SalesforceTimeoutProps {
  setSalesforceTimeout: (boolean) => void;
  handleSalesforceTextInput: () => void;
}

const SalesforceTimeout = ({ handleSalesforceTextInput, setSalesforceTimeout }: SalesforceTimeoutProps) => (
  <Modal title="SalesForce Error" onClose={() => setSalesforceTimeout(false)}>
    <Text align="center">We did not receive a response from SalesForce. Please try again.</Text>
    <Div>
      <Button onClick={handleSalesforceTextInput} variant="primary">Try Again</Button>
      <Button onClick={() => setSalesforceTimeout(false)}>Cancel</Button>
    </Div>
  </Modal>
);

export default SalesforceTimeout;
