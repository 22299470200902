import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Text } from 'shared-library';
import { getEnvironment } from 'packages/locations';

const Div = styled.div`
  justify-content: space-around;
  display: flex;
`;

interface StudyMismatchModalProps {
  setStudyIdMismatch: (boolean) => void;
  opportunityId: string;
}

const StudyIdMismatchModal = ({ setStudyIdMismatch, opportunityId }: StudyMismatchModalProps) => {
  const environment = getEnvironment();
  return (
    <Modal title="Study ID Mismatch" onClose={() => setStudyIdMismatch(false)}>
      <Text align="center">
        Please confirm this is the correct opportunity in Salesforce and it is associated with this
        study.
      </Text>
      <Text align="center">
        If it is the correct opportunity, add or update the StudyID in Salesforce and try again.
      </Text>
      <Text align="center">
        If it is not the correct opportunity, enter the correct opportunity ID and try again.
      </Text>
      <Div>
        <Button
          disabled={!opportunityId}
          onClick={() =>
            window.open(
              `https://${
                environment !== 'PRODUCTION'
                  ? 'prenuvo--prenuvosb.lightning.force.com'
                  : 'prenuvo.lightning.force.com'
              }/lightning/r/Opportunity/${opportunityId}/view`,
              '_blank',
            )
          }
          variant="primary"
          size="large"
        >
          View on Salesforce
        </Button>
        <Button onClick={() => setStudyIdMismatch(false)} variant="primary" size="large">
          Cancel
        </Button>
      </Div>
    </Modal>
  );
};

export default StudyIdMismatchModal;
