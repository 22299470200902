import { getMomentFromUnixTimestamp } from 'packages/formatters';
import React from 'react';
import {
  Section,
  colors,
  Button,
  LoadingSpinner,
  Text,
  Card,
  Heading,
  Link,
} from 'shared-library';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Label, Row } from '../Layout';
import BookingForm from '../StudyDetails/BookingForm';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectSalesforce } from '../../store/salesforce/selectors';
import { updateSf } from '../../store/studies/actions';
import SalesforceTextInput from '../StudyDetails/SalesforceTextInput';

interface SalesforceSectionProps {
  actionsProcessing: any;
  setActionProcessing: any;
  referringMember: any;
  handleActionDispatch: any;
  actionsAvailable: any;
}
const SalesforceSection = ({
  actionsProcessing,
  setActionProcessing,
  referringMember,
  handleActionDispatch,
  actionsAvailable,
}: SalesforceSectionProps): React.ReactElement => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const dispatch = useDispatch();
  const salesforce = useSelector(selectSalesforce);

  const bookingVarsDone = !!(
    study.sfOpportunityID &&
    study.salesforce &&
    study.salesforce.opportunity.loaded &&
    study.salesforce.opportunity.LeadSource &&
    study.salesforce.opportunity.Type
  );

  const handleSfUpdateSubmit = async (form) => {
    setActionProcessing({ ...actionsProcessing, salesforceUpdate: true });
    const updatedOpportunity = {
      ...study.salesforce?.opportunity,
      source: form.leadSource.value,
      LeadSource: form.leadSource.value,
      type: form.type.value,
      Type: form.type.value,
    };
    await updateSf(study.studyID, study.sfOpportunityID, updatedOpportunity)(dispatch);
    setActionProcessing({ ...actionsProcessing, salesforceUpdate: false });
  };

  return (
    <Section label="4" title="Confirm source and assignment" viewState="show">
      {study.sfOpportunityID && study.salesforce?.opportunity.loaded && (
        <>
          {study.salesforce?.opportunity.loaded &&
            !study.salesforce?.opportunity.loading &&
            !bookingVarsDone && (
              <>
                <Row>
                  <Text>
                    There are important attribution variables that are not set for this patient.
                  </Text>
                </Row>
                <BookingForm
                  isSaving={actionsProcessing?.salesforceUpdate}
                  opportunity={study.salesforce?.opportunity}
                  onSubmit={handleSfUpdateSubmit}
                  onCancelEdit={null}
                  fields={salesforce}
                />
              </>
            )}

          {study.salesforce?.opportunity.loaded &&
            bookingVarsDone &&
            study.status.sourceMemberPrenuvoID &&
            study.status.sourceMemberContactOk && (
              <>
                {study.status.sourceMemberPrenuvoID &&
                  referringMember &&
                  !study.status.sourceMemberCommunicationDone && (
                    <>
                      <Row>
                        <Text>
                          The member was referred by {referringMember?.firstname}
                          {referringMember?.lastname} and indicated that it is okay to send a
                          thankyou to the referring member.
                        </Text>
                      </Row>
                      <Row>
                        <Column>
                          <Label>SMS</Label>
                          <div>
                            <Link
                              onClick={() => handleActionDispatch('sourceMemberThankYouSMS')}
                              disabled={
                                actionsProcessing.sourceMemberThankYouSMS || !actionsAvailable.sms
                              }
                            >
                              <>
                                {actionsProcessing.sourceMemberThankYouSMS && (
                                  <LoadingSpinner color={colors.white} size="small" />
                                )}
                                Referral thank you
                              </>
                            </Link>
                          </div>
                          {study.status.sourceMemberCommunicationSMSTime && (
                            <div>
                              Last:{' '}
                              {getMomentFromUnixTimestamp(
                                study.status.sourceMemberCommunicationSMSTime,
                              ).format('DD MMM YYYY')}
                            </div>
                          )}
                        </Column>
                        <Column>
                          <Label>Email</Label>
                          <div>
                            <Link
                              onClick={() => handleActionDispatch('sourceMemberThankYouEmail')}
                              disabled={actionsProcessing.sourceMemberThankYouEmail}
                            >
                              <>
                                {actionsProcessing.sourceMemberThankYouEmail && (
                                  <LoadingSpinner color={colors.white} size="small" />
                                )}
                                Referral thank you
                              </>
                            </Link>
                          </div>
                          {study.status.sourceMemberCommunicationEmailTime && (
                            <div>
                              Last:{' '}
                              {getMomentFromUnixTimestamp(
                                study.status.sourceMemberCommunicationEmailTime,
                              ).format('DD MMM YYYY')}
                            </div>
                          )}
                        </Column>
                      </Row>
                      <Row>
                        <Button
                          variant="primary"
                          onClick={() => handleActionDispatch('sourceMemberCommunicationDone')}
                          disabled={actionsProcessing.sourceMemberCommunicationDone}
                          size="medium"
                        >
                          {actionsProcessing.sourceMemberCommunicationDone ? (
                            <LoadingSpinner color={colors.white} size="small" />
                          ) : (
                            `Completed referrer notification`
                          )}
                        </Button>
                      </Row>
                    </>
                  )}
                {study.status.sourceMemberPrenuvoID && study.status.sourceMemberCommunicationDone && (
                  <Card backgroundColor={colors.smoke}>
                    <Heading color={colors.primary} size={5}>
                      Completed
                    </Heading>
                    <Text>The important attribution variables were completed.</Text>
                  </Card>
                )}
              </>
            )}
          {!study.status.sourceMemberPrenuvoID && bookingVarsDone && (
            <Card backgroundColor={colors.smoke}>
              <Heading color={colors.primary} size={5}>
                Completed
              </Heading>
              <Text>The important attribution variables were completed.</Text>
            </Card>
          )}
        </>
      )}
      {study.sfOpportunityID && study.salesforce?.opportunity.error && (
        <>
          <Row>
            <Text>Error loading salesforce opportunity.</Text>
          </Row>
          <SalesforceTextInput />
        </>
      )}
      {study.sfOpportunityID && study.salesforce?.opportunity.loading && (
        <Row>
          <Text>Salesforce opportunity is loading.</Text>
        </Row>
      )}
      {!study.sfOpportunityID && (
        <>
          <Row>
            <Text>No salesforce opportunity for this study.</Text>
          </Row>
          <SalesforceTextInput />
        </>
      )}
    </Section>
  );
};
export default SalesforceSection;
