import { ApolloLink, ApolloProvider, useQuery } from '@apollo/client';
import moment from 'moment';
import { fetchHiResource, fetchPiiResource } from 'packages/apis';
import { formatUnixDatetime } from 'packages/formatters';
import { getHpdBackendUrl } from 'packages/locations';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Button,
  colors,
  getApolloClient,
  Heading,
  LoadingSpinner,
  Notification,
  practitionerQueries,
  Text,
} from 'shared-library';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import labels from '../../labels';
import { buildPatientSelector } from '../../store/patients/selectors';
import { addLogItem, releasedToDoctor, setLoadingCdDicom } from '../../store/studies/actions';
import { buildStudySelector, selectIsLoadingCDDicom } from '../../store/studies/selectors';
import { formatBookingTimeString } from '../../lib';
import AutomatedFollowUpModal from './AutomatedFollowUpModal';
import { selectSession } from '../../store/session/selectors';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 30px;
`;

const Divider = styled.hr`
  color: ${colors.gray};
  opacity: 0.2;
  margin: 0 0 20px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled(Button)`
  min-width: 85px;
`;

const LastSendWrapper = styled.div`
  justify-self: flex-end;
  margin-right: 10px;
`;

export interface Practitioner {
  salutation: string;
  firstName: string;
  lastName: string;
  profession: string;
  id: string;
  prenuvoId: string;
  lastSent: string;
  contact: {
    address: string;
    address2: string;
    faxNumber: string;
    emailAddress: string;
  };
  locations: {
    edges: [
      {
        node: {
          id: string;
          locationName: string;
          contact: {
            faxNumber: string;
          };
          practitionerContact: {
            faxNumber: string;
          };
          practice: {
            practiceName: string;
          };
        };
      },
    ];
  };
}

interface ButtonGroupProps {
  handleNoteSubmit: (
    categoryName: string,
    practitioner: Practitioner,
    practitionerLocationId: string,
  ) => void;
  practitioner: Practitioner;
  practitionerLocationId: string;
  buttonsActivated: boolean;
}

export type FollowUpType = 'email' | 'fax';

interface FollowUpInfo {
  practitioner: Practitioner;
  action: FollowUpType;
}

const ButtonGroup = ({
  handleNoteSubmit,
  practitioner,
  practitionerLocationId,
  buttonsActivated,
}: ButtonGroupProps): ReactElement<ButtonGroupProps> => {
  const { isLoadingCdDicom } = useSelector(selectIsLoadingCDDicom);
  return (
    <ButtonContainer>
      {['FAX', 'EMAIL', 'CD', 'USB'].map((tracking) => (
        <ButtonWrapper
          key={tracking}
          variant="secondary"
          type="button"
          size="xsmall"
          onClick={() => handleNoteSubmit(tracking, practitioner, practitionerLocationId)}
          disabled={isLoadingCdDicom || !buttonsActivated}
        >
          {isLoadingCdDicom ? <LoadingSpinner color={colors.primary} size="small" /> : tracking}
        </ButtonWrapper>
      ))}
    </ButtonContainer>
  );
};

const SendStudyTracking = (): ReactElement => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [selectedFollowUpInfo, setSelectedFollowUpInfo] = useState<FollowUpInfo | undefined>();
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const { data: userSession } = useSelector(selectSession);
  const patient = useSelector(buildPatientSelector(study.patient));
  const { refetch: getPractitionerData } = useQuery(practitionerQueries.PRACTITIONERS_DETAILS, {
    skip: true,
  });
  const [practitionersIds, setPractitionersIds] = useState([]);
  const [practitionerInformation, setPractitionerInformation] = useState<Practitioner[]>([]);

  useEffect(() => {
    if (study.practitioners) {
      const allIds = Object.keys(study.practitioners).reduce((ids, keys) => {
        const additionalIds = [];
        switch (keys) {
          case 'primary':
          case 'referring':
            if (study.practitioners[keys]?.practitionerID) {
              return [
                ...ids,
                {
                  id: study.practitioners[keys]?.practitionerID,
                  locationId: study.practitioners[keys]?.locationID,
                },
              ];
            }
            return [...ids];
          case 'additional':
            study.practitioners[keys]?.forEach((additional) => {
              additionalIds.push({
                id: additional.practitionerID,
                locationId: additional.locationID,
              });
            });
            return [...ids, ...additionalIds];
          default:
            return [...ids];
        }
      }, []);
      setPractitionersIds([...new Set(allIds)]);
    }
  }, [study]);

  useEffect(() => {
    const getPractitionerInformation = async () => {
      if (practitionersIds) {
        const newPractitioners = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < practitionersIds.length; index++) {
          // eslint-disable-next-line no-await-in-loop
          const { data: practitionerData } = await getPractitionerData({
            id: practitionersIds[index]?.id,
          });
          if (practitionerData.practitioner) {
            newPractitioners.push(practitionerData.practitioner);
          }
        }
        setPractitionerInformation(newPractitioners);
      }
    };

    getPractitionerInformation();
  }, [practitionersIds]);

  const addStudyTrackingLog = async (
    categoryName: string,
    practitioner: Practitioner,
    displayToast = true,
  ) => {
    try {
      const category = 'Study Tracking';
      const content = `
        Study marked as sent to ${practitioner.firstName} ${
        practitioner.lastName
      } by ${categoryName.toLowerCase()}
        id: ${practitioner.id}
        `;

      await dispatch(addLogItem(selectedStudyId, content, category));

      if (displayToast) {
        addToast(Notification.create(category, content), {
          appearance: 'success',
        });
      }

      if (!study.status?.releasedToDoctor) {
        await dispatch(releasedToDoctor(selectedStudyId));
      }
    } catch (error) {
      addToast(
        Notification.create(
          'Adding tracking note failed',
          'Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      throw new Error(error);
    }
  };

  const onTrackingCDDicom = async (
    categoryName: string,
    practitioner: Practitioner,
    practitionerLocationId: string,
  ) => {
    try {
      dispatch(setLoadingCdDicom(true));

      addStudyTrackingLog(categoryName, practitioner);

      if (study.location.address.country === 'USA') {
        addToast(
          Notification.create('DICOM images are downloading. This may take a few minutes.', ''),
          { appearance: 'success' },
        );
        let packageName = 'Not scheduled';
        if (study.booking.length > 0) {
          packageName = labels.packages[study.items.primary.skuID];
        }

        await fetchPiiResource(`/api/v1/admin/${study.patient}`, 'POST', {
          scanId: selectedStudyId,
          scanLocation: study.location.address.name,
          scanType: packageName,
          scanDate: formatBookingTimeString(study.booking[0], study.location.timezone),
          practitionerId: practitioner.id,
          practitionerName: `${practitioner.firstName} ${practitioner.lastName}`,
          practitionerInstitution: practitioner.locations.edges.find(
            ({ node }) => node.id === practitionerLocationId,
          )?.node?.locationName,
          practitionerAddress1: practitioner.contact.address,
          practitionerAddress2: practitioner.contact.address2,
        });

        await fetchHiResource(`/api/v1/dicom/${selectedStudyId}`, 'POST', {
          firstname: patient.firstname,
          lastname: patient.lastname,
          dob: moment(patient.dob).format('YYYYMMDD'),
        });
        addToast(Notification.create('Export successful', ''), { appearance: 'success' });
      }
    } catch (error) {
      addToast(
        Notification.create(
          'Adding tracking note failed',
          'Give it another try and please report an issue if this persists.',
        ),
        { appearance: 'error' },
      );
      throw new Error(error);
    } finally {
      dispatch(setLoadingCdDicom(false));
    }
  };

  const handleNoteSubmit = async (
    categoryName: string,
    practitioner: Practitioner,
    practitionerLocationId: string,
  ) => {
    switch (categoryName) {
      case 'CD':
        onTrackingCDDicom(categoryName, practitioner, practitionerLocationId);
        break;
      case 'EMAIL':
        setSelectedFollowUpInfo({ practitioner, action: 'email' });
        break;
      case 'FAX':
        setSelectedFollowUpInfo({ practitioner, action: 'fax' });
        break;
      default:
        addStudyTrackingLog(categoryName, practitioner);
    }
  };

  const getLastSentDate = (practitionerId: string) => {
    if (!study) {
      return null;
    }

    const sentDate = study.log
      .filter((logs) => logs.category === 'Study Tracking')
      .sort((a, b) => Number(b.time) - Number(a.time))
      .find((logs) => logs.content.includes(practitionerId));

    return formatUnixDatetime(sentDate?.time) || 'Not Sent';
  };

  return (
    <Container>
      {practitionersIds.length > 0 ? (
        <>
          <Row>
            <Heading noMargin size={7}>
              Full Name
            </Heading>
            <Heading noMargin size={7}>
              Practitioner
            </Heading>
            <Heading noMargin size={7}>
              Actions
            </Heading>
            <LastSendWrapper>
              <Heading noMargin size={7}>
                Last Send
              </Heading>
            </LastSendWrapper>
          </Row>
          <Divider />
          {practitionerInformation.length > 0 ? (
            practitionerInformation.map(
              (practitioner) =>
                practitioner && (
                  <Row key={practitioner.id}>
                    <Text>{`${practitioner.firstName} ${practitioner.lastName}`}</Text>
                    <Text>
                      {practitioner.profession
                        .toLowerCase()
                        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase())}
                    </Text>
                    <ButtonGroup
                      handleNoteSubmit={handleNoteSubmit}
                      practitioner={practitioner}
                      practitionerLocationId={
                        practitionersIds.find((ids) => ids.id === practitioner.id)?.locationId
                      }
                      buttonsActivated={study.status.published}
                    />
                    <LastSendWrapper>
                      <Text>{getLastSentDate(practitioner.id)}</Text>
                    </LastSendWrapper>
                  </Row>
                ),
            )
          ) : (
            <LoadingSpinner size="small" />
          )}
        </>
      ) : (
        <Heading size={7}>There is no practitioner assign to this study</Heading>
      )}

      {!study.status.published && (
        <Text>Actions can be performed once the report is published</Text>
      )}

      {selectedFollowUpInfo && (
        <AutomatedFollowUpModal
          action={selectedFollowUpInfo.action}
          study={study}
          recipient={selectedFollowUpInfo.practitioner}
          patient={patient}
          senderName={userSession.fullname}
          addTrackingLog={addStudyTrackingLog}
          onModalClose={() => setSelectedFollowUpInfo(undefined)}
          practitionerIds={practitionersIds}
        />
      )}
    </Container>
  );
};

const SendStudyTrackingWithApollo = () => {
  const [apiEndpoint] = useState(getHpdBackendUrl());
  const apolloClient = getApolloClient(apiEndpoint);

  return useMemo(() => {
    return (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            <SendStudyTracking />
          </ApolloProvider>
        )}
      </>
    );
  }, []);
};

export default SendStudyTrackingWithApollo;
