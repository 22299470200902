import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Label, Tag, Text } from 'shared-library';

import { fetchAppsResource, fetchPiiResource } from 'packages/apis';

import { Column, Row } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { User } from '../../types';
import { HPPBookingContainer } from './TaskDetails.styles';

const HPPBooking: React.FC = () => {
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);

  const [hppBooked, setHPPBooked] = useState('No');
  const [partnerName, setPartnerName] = useState('N/A');
  const [referredHPAtCreationName, setReferredHPAtCreationName] = useState('N/A');
  const [bookedByName, setBookedByName] = useState('N/A');

  const getNamesForHPPSection = async () => {
    setHPPBooked('Yes');
    setPartnerName('Loading...');
    if (study?.config?.referredHPAtCreation) {
      setReferredHPAtCreationName('Loading...');
    }
    if (study?.config?.bookedBy) {
      setBookedByName('Loading...');
    }
    try {
      const { parsedBody: partnerDetails } = await fetchAppsResource(
        `/partners/${study.partnerID}`,
      );
      setPartnerName(partnerDetails?.name);
    } catch {
      setPartnerName('There was an error getting the partner name');
    }
    try {
      const { parsedBody: referredHP } = await fetchPiiResource<User>(
        `/api/v1/userV2/${study?.config?.referredHPAtCreation}`,
      );
      setReferredHPAtCreationName(referredHP.fullname);
      if (
        study?.config?.bookedBy &&
        study?.config?.bookedBy === study?.config?.referredHPAtCreation &&
        referredHP?.fullname
      ) {
        setBookedByName(referredHP.fullname);
      } else {
        const { parsedBody: bookedBy } = await fetchPiiResource<User>(
          `/api/v1/userV2/${study?.config?.bookedBy}`,
        );
        setBookedByName(bookedBy.fullname);
      }
    } catch {
      if (referredHPAtCreationName === 'N/A') {
        setReferredHPAtCreationName(`There was an error getting the referred HP's name`);
      }
      if (bookedByName === 'N/A') {
        setBookedByName(`There was an error getting the booking account's name`);
      }
    }
  };

  useEffect(() => {
    if (study?.config?.HPPBooking === true && hppBooked !== 'Yes') {
      getNamesForHPPSection();
    }
  }, [study?.config?.HPPBooking]);

  return (
    <HPPBookingContainer>
      <Row>
        <Column>
          <Label>HPP Booked</Label>
          <Text>
            <Tag>{hppBooked}</Tag>
          </Text>
        </Column>
        <Column>
          <Label>Partner Entity</Label>
          <Text>{partnerName}</Text>
        </Column>
        <Column>
          <Label>HP Referred</Label>
          <Text>{referredHPAtCreationName}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Label>Booking Account</Label>
          <Text>{bookedByName}</Text>
        </Column>
      </Row>
    </HPPBookingContainer>
  );
};

export default HPPBooking;
