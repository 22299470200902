import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';

import { Notification } from 'shared-library';

import { fetchAppsResource, fetchPiiResource } from 'packages/apis';
import { buildStudySelector } from '../../store/studies/selectors';
import { buildPatientSelector } from '../../store/patients/selectors';
import { selectSession } from '../../store/session/selectors';
import { updateConsultingPractitioner, updateStatus } from '../../store/studies/actions';

import { captureError, formatBookingTimeString, getTimeFromNow } from '../../lib';
import { User } from '../../types';

import RemindAppointment from './RemindAppointment';
import CheckinMember from './CheckinMember';
import PaymentSection from './PaymentSection';
import SalesforceSection from './SalesforceSection';
import PostReleaseContact from './PostReleaseContact';
import FeedbackSection from './FeedbackSection';
import AmplifyFeedback from './AmplifyFeedback';
import HealthPractitionerSection from './HealthPractitionerSection';
import HPPBooking from './HPPBooking';

const TaskDetails = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const { studyID } = study;
  const { data: session } = useSelector(selectSession);
  const patient = useSelector(buildPatientSelector(study.patient));
  const [actionsProcessing, setActionProcessing] = useState({
    checkin: false,
    reminded: false,
    passwordReset: false,
    appointmentReminderViaSms: false,
    checkinWhereAreYouViaSms: false,
    googleMapsClinicLinkViaSms: false,
    callPatient: false,
    feedbackReminder: false,
    feedbackReminderViaEmail: false,
    feedbackReminderViaSMS: false,
    feedbackAmplificationRequest: false,
    feedbackAmplificationDenied: false,
    feedbackAmplificationDone: false,
    feedbackNegativeDone: false,
    salesforceUpdate: false,
    testFax: false,
    reminderRingoutCall: false,
    resendConfirmation: false,
    patientReportNotification: false,
    patientConsultRequested: false,
    patientConsultNotRequested: false,
    providerReportNotification: false,
    reportReleasedViaSmsNotSensitiveWithPrimaryCare: false,
    reportReleasedViaSmsNotSensitiveWithoutPrimaryCare: false,
    reportReleasedViaSmsSensitiveWithPrimaryCare: false,
    reportReleasedViaSmsSensitiveWithoutPrimaryCare: false,
    sourceMemberThankYouEmail: false,
    sourceMemberThankYouSMS: false,
    sourceMemberCommunicationDone: false,
    medicalHistoryReminderViaSMS: false,
  });
  const actionsAvailable = {
    checkin: !study.status.checkin,
    reminded: !study.status.reminded,
  };

  /*
   Section 1: Remind patients
  */
  const handleReminded = async () => {
    setActionProcessing({ ...actionsProcessing, reminded: true });
    await updateStatus(
      studyID,
      {
        reminded: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, reminded: false });
  };

  /*
   Section 2: Checkin the patient after confirming details
  */
  const dobMoment = patient && patient.dob && moment(patient.dob);
  const formattedPatient = {
    ...patient,
    dobMoment,
  };

  const handleCheckin = async () => {
    setActionProcessing({ ...actionsProcessing, checkin: true });
    await updateStatus(
      studyID,
      {
        checkin: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, checkin: false });
  };
  const handleMedicalHistoryReminderViaEmail = async () => {
    setActionProcessing({ ...actionsProcessing, medicalHistoryReminderViaSMS: true });
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/email/medical-history-reminder`);
      addToast(
        Notification.create(
          'Referral thank you email sent',
          `An email requesting medical history completion has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setActionProcessing({ ...actionsProcessing, medicalHistoryReminderViaSMS: false });
      await updateStatus(
        studyID,
        {
          reminderMedicalHistoryEmailSent: true,
        },
        session.userId,
      )(dispatch);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot send referring member thank you email.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setActionProcessing({ ...actionsProcessing, medicalHistoryReminderViaSMS: false });
    }
  };

  /*
   Section 5: Confirm source and assignment
   */

  const [referringMember, setReferringMember] = useState(null);
  useEffect(() => {
    const fetchSelectedUser = async () => {
      const response = await fetchPiiResource<User>(
        `/api/v1/userV2/${study.status.sourceMemberPrenuvoID}`,
      );
      const user = response.parsedBody;
      setReferringMember(user);
    };
    if (study.status.sourceMemberPrenuvoID) {
      fetchSelectedUser();
    }
  }, []);

  const handleReferralThankYouEmail = async () => {
    setActionProcessing({ ...actionsProcessing, sourceMemberThankYouEmail: true });
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/email/referral-thankyou`);
      addToast(
        Notification.create(
          'Referral thank you email sent',
          `An email thanking ${referringMember?.firstname} has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setActionProcessing({ ...actionsProcessing, sourceMemberThankYouEmail: false });
      await updateStatus(
        studyID,
        {
          sourceMemberCommunicationEmail: true,
        },
        session.userId,
      )(dispatch);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot send referring member thank you email.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setActionProcessing({ ...actionsProcessing, sourceMemberThankYouEmail: false });
    }
  };
  const handleSourceMemberCommunicationDone = async () => {
    setActionProcessing({ ...actionsProcessing, sourceMemberCommunicationDone: true });
    await updateStatus(
      studyID,
      {
        sourceMemberCommunicationDone: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, sourceMemberCommunicationDone: false });
  };

  /*
   Section 6: Contact patient that report has been released
  */
  const handlePatientReportNotification = async () => {
    setActionProcessing({ ...actionsProcessing, patientReportNotification: true });
    await updateStatus(
      studyID,
      {
        patientReportNotification: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, patientReportNotification: false });
  };
  const handleProviderReportNotification = async () => {
    setActionProcessing({ ...actionsProcessing, providerReportNotification: true });
    await updateStatus(
      studyID,
      {
        providerReportNotification: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, providerReportNotification: false });
  };

  const handlePatientConsultRequested = async (consultRequested: boolean) => {
    const actionProcessingKey = consultRequested
      ? 'patientConsultRequested'
      : 'patientConsultNotRequested';
    setActionProcessing({ ...actionsProcessing, [actionProcessingKey]: true });
    await updateConsultingPractitioner(studyID, {
      requestedStatus: consultRequested ? 'requested' : 'not requested',
    })(dispatch);
    setActionProcessing({ ...actionsProcessing, patientConsultRequested: false });
  };

  /*
   Section 7: Obtain feedback from patients
   */
  const handleFeedbackEmail = async () => {
    try {
      const { email } = patient;
      setActionProcessing({ ...actionsProcessing, feedbackReminderViaEmail: true });
      await fetchAppsResource(`/study/${study.studyID}/email/feedback-reminder`);
      addToast(
        Notification.create(
          'Feedback email sent',
          `An email requesting feedback has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setActionProcessing({ ...actionsProcessing, feedbackReminderViaEmail: false });
      await updateStatus(
        studyID,
        {
          feedbackReminderEmailSent: true,
        },
        session.userId,
      )(dispatch);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot resend feedback.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setActionProcessing({ ...actionsProcessing, feedbackReminderViaEmail: false });
    }
  };

  // Handle SMS dispatch
  const handleSMSDispatch = async (action) => {
    const message = {
      number: patient.phoneNumber,
      content: 'Prenuvo is trying to contact you',
      success: {
        title: 'SMS sent',
        body: `An SMS was sent by ${session?.firstname} to ${patient.phoneNumber}`,
      },
      error: {
        title: 'SMS send failed',
        body:
          'Something unexpected went wrong. Give it another try and please report an issue if this persists.',
      },
    };
    switch (action) {
      case 'appointmentReminderViaSms': {
        const daysUntilAppointment = getTimeFromNow(study.booking[0].utcStart, 'days');
        const hoursUntilAppointment = getTimeFromNow(study.booking[0].utcStart, 'hours');
        switch (daysUntilAppointment) {
          case 0:
            switch (hoursUntilAppointment) {
              case 0:
                message.content = `Hi ${
                  patient?.firstname
                }, a friendly reminder about your appointment today at Prenuvo at ${formatBookingTimeString(
                  study.booking[0],
                  study.location.timezone,
                  'hour-start',
                )}. Please arrive with plenty of time. If you have any questions we can be reached on +1-833-773-6886. \n\nCheers, \n${
                  session?.firstname
                }`;
                break;
              case 1:
              case 2:
              case 3:
                message.content = `Hi ${
                  patient?.firstname
                }, a friendly reminder about your appointment today at Prenuvo at ${formatBookingTimeString(
                  study.booking[0],
                  study.location.timezone,
                  'hour-start',
                )}. Please arrive at least 20 minutes before this time. If you have any questions we can be reached on +1-833-773-6886. \n\nCheers, \n${
                  session?.firstname
                }`;
                break;
              case 4:
                message.content = `Hi ${
                  patient?.firstname
                }, a friendly reminder about your appointment today at Prenuvo at ${formatBookingTimeString(
                  study.booking[0],
                  study.location.timezone,
                  'hour-start',
                )}. Please start fasting and arrive at least 20 minutes before this time. If you have any questions we can be reached on +1-833-773-6886. \n\nCheers, \n${
                  session?.firstname
                }`;
                break;
              default:
                message.content = `Hi ${
                  patient?.firstname
                }, a friendly reminder about your appointment today at Prenuvo at ${formatBookingTimeString(
                  study.booking[0],
                  study.location.timezone,
                  'hour-start',
                )}. Please remember to fast for 4 hours before and arrive 20 mins before your appointment. If you have any questions we can be reached on +1-833-773-6886. \n\nLooking forward to seeing you, \n${
                  session?.firstname
                }`;
            }
            break;
          case 1:
            message.content = `Hi ${
              patient?.firstname
            }, this is a reminder text about your appointment tomorrow at Prenuvo at ${formatBookingTimeString(
              study.booking[0],
              study.location.timezone,
              'hour-start',
            )}. Please remember to fast for 4 hours before and arrive 20 mins before your appointment. If you have any questions we can be reached on +1-833-773-6886. \n\nLooking forward to seeing you, \n${
              session?.firstname
            }`;
            break;
          default:
            message.content = `Hi ${
              patient?.firstname
            }, this is a reminder text about your appointment at Prenuvo on ${formatBookingTimeString(
              study.booking[0],
              study.location.timezone,
            )}. Please remember to fast for 4 hours before and arrive 20 mins before your appointment. If you have any questions we can be reached on +1-833-773-6886. \n\nLooking forward to seeing you, \n${
              session?.firstname
            }`;
        }
        message.success.title = `${patient?.firstname} reminded of appointment via SMS`;
        message.success.body = `An appointment reminder SMS was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      }
      case 'checkinWhereAreYouViaSms':
        message.content = `We are expecting you at Prenuvo for your scan that is to start in a few minutes. \n\nChecking to see your ETA? \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} ETA request sent`;
        message.success.body = `An SMS requesting their ETA was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'googleMapsClinicLinkViaSms':
        message.content = `Here is a Google Maps link to the Prenuvo clinic for your scan on ${formatBookingTimeString(
          study.booking[0],
          study.location.timezone,
        )}. \nhttps://www.google.com/maps/place/${study.location.address.address.replace(
          /\s/g,
          '+',
        )}+${study.location.address.city.replace(
          /\s/g,
          '+',
        )}+${study.location.address.state.replace(
          /\s/g,
          '+',
        )}+${study.location.address.postalCode.replace(/\s/g, '+')} \n\nBest wishes, ${
          session?.firstname
        }`;
        message.success.title = `${patient?.firstname} was sent directions`;
        message.success.body = `An SMS with the google map link was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'medicalHistoryReminderViaSMS': {
        const resetLink = await (
          await fetchPiiResource('/api/v1/userV2/generateResetLink', 'POST', {
            prenuvoID: study.patient,
          })
        ).parsedBody.resp;
        message.content = `Hi ${patient?.firstname}. Just a short reminder for you to complete your medical history in advance of your Prenuvo scan. \n\nIf you have never logged into Prenuvo before create a password and get started here: https://www.prenuvo.com/booking/conversion?studyObj=${study.studyID}&resetLink=${resetLink}. \n\nIf you have already created a password, login at https://www.prenuvo.com. \n\nThanks, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} reminded of medical history via SMS`;
        message.success.body = `A SMS reminding the patient about the medical history was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      }
      case 'postScanNextStepsViaSms':
        message.content = `A short note letting you know that your images have been sent to our highly trained team of radiologists and your medical report should be available in 5-10 business days. \n\nIf you have any questions we can be reached on +1-833-773-6886. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was sent an update`;
        message.success.body = `An SMS status update was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'postScanPrimaryCareInformation':
        message.content = `The team at Prenuvo needs your primary care physician details to add to your records should there be any medical followup required. \n\nPlease email us at hello@prenuvo.com or call us at +1-833-773-6886 with their details. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was asked for PCP`;
        message.success.body = `An SMS requesging PCP details was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'reportReleasedViaSmsNotSensitiveWithPrimaryCare':
        message.content = `Hi ${patient?.firstname}. Your Prenuvo report has been completed. Please log in to the Prenuvo mobile app or web dashboard to view it. \n\nWhile you indicated you have a primary care physician, I wanted to let you know that if you require it, a member of our clinical staff is available to take you through your medical report. Just call the Prenuvo team at +1-833-773-6886. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was notified of the report release`;
        message.success.body = `An SMS informing the member of their non-sensitive report release was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'reportReleasedViaSmsNotSensitiveWithoutPrimaryCare':
        message.content = `Hi ${patient?.firstname}. Your Prenuvo report has been completed. Please log in to the Prenuvo mobile app or web dashboard to view it. \n\nAs you indicated you do not have a primary care physician, a member of our clinical staff is available to take you through your medical report if you would like. Just call the Prenuvo team at +1-833-773-6886. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was notified of the report release`;
        message.success.body = `An SMS informing the member of their non-sensitive report release was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'reportReleasedViaSmsSensitiveWithPrimaryCare':
        message.content = `Hi ${patient?.firstname}. Your Prenuvo report has been finalized and sent to your primary care physician. They should contact you to go through the results; if not please contact them at your earliest convenience. \n\nIf you have any questions, the Prenuvo team is here to help you at +1-833-773-6886. \n\nBest wishes, \n\n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was notified of the report release and need to contact their physician`;
        message.success.body = `An SMS informing the member of their report release and to contact their physician was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'reportReleasedViaSmsSensitiveWithoutPrimaryCare':
        message.content = `Hi ${patient?.firstname}. We wanted to let you know that your Prenuvo report has been finalized.  Please call the Prenuvo team at +1-833-773-6886 to arrange an appointment with the medical staff to go through your results. \n\nBest wishes, ${session?.firstname}`;
        message.success.title = `${patient?.firstname} was notified of the report release and need to arrange an NP consult`;
        message.success.body = `An SMS informing the member of their report release and to contact us for an NP consult was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'feedbackReminderViaSMS':
        message.content = `Hi ${patient?.firstname}. I sent you an email on this, but it would be great to get some feedback on your recent Prenuvo experience. \n\nPositive feedback we make sure gets back to individual team members, areas for improvement we take seriously. \n\nYou can provide feedback at https://www.prenuvo.com/surveys/study/${study.studyID}/satisfaction. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${patient?.firstname} was send a feedback SMS`;
        message.success.body = `An SMS requesting feedback was sent to ${patient.phoneNumber} from %sendNumber%`;
        break;
      case 'sourceMemberThankYouSMS':
        message.number = referringMember.phone_number;
        message.content = `Hi ${referringMember?.firstname}. I just had the pleasure of meeting, ${patient?.firstname}, who you told about what we do at Prenuvo. \n\nOur biggest challenge is spreading the world in order to connect with people who can benefit from what we do, and hopefully save lives in the process.\n\nSo this means a lot to us that you did this. \n\nBest wishes, \n${session?.firstname}`;
        message.success.title = `${referringMember?.firstname} was send a thank you SMS`;
        message.success.body = `An SMS thanking ${referringMember?.firstname} for referring us ${patient?.firstname} to ${referringMember.phone_number} from %sendNumber%`;
        message.imageUrl = '/static/images/sms-thankyou.jpg';
        break;
      default:
    }
    setActionProcessing({ ...actionsProcessing, [action]: true });
    sendSMS(message, async () => {
      setActionProcessing({ ...actionsProcessing, [action]: false });
      switch (action) {
        case 'sourceMemberThankYouSMS':
          await updateStatus(
            studyID,
            {
              sourceMemberCommunicationSMS: true,
            },
            session.userId,
          )(dispatch);
          break;
        case 'appointmentReminderViaSms':
          await updateStatus(
            studyID,
            {
              reminderSMSSent: true,
            },
            session.userId,
          )(dispatch);
          break;
        case 'feedbackReminderViaSMS':
          await updateStatus(
            studyID,
            {
              feedbackReminderSMSSent: true,
            },
            session.userId,
          )(dispatch);
          break;
        case 'medicalHistoryReminderViaSMS':
          await updateStatus(
            studyID,
            {
              reminderMedicalHistorySMSSent: true,
            },
            session.userId,
          )(dispatch);
          break;

        default:
      }
    });
  };

  /*
    ACTIONS
  */
  const handleActionDispatch = (action) => {
    switch (action) {
      // Email actions
      case 'feedbackReminderViaEmail':
        handleFeedbackEmail();
        break;
      case 'sourceMemberThankYouEmail':
        handleReferralThankYouEmail();
        break;
      case 'medicalHistoryReminderViaEmail':
        handleMedicalHistoryReminderViaEmail();
        break;
      // Status actions
      case 'checkin':
        handleCheckin();
        break;
      case 'reminded':
        handleReminded();
        break;
      case 'patientReportNotification':
        handlePatientReportNotification();
        break;
      case 'providerReportNotification':
        handleProviderReportNotification();
        break;
      case 'patientConsultRequested':
        handlePatientConsultRequested(true);
        break;
      case 'patientConsultNotRequested':
        handlePatientConsultRequested(false);
        break;
      case 'sourceMemberCommunicationDone':
        handleSourceMemberCommunicationDone();
        break;
      case 'sourceMemberCommunicationDenied':
        // handleSourceMemberCommunicationDenied();
        break;
      // SMS actions
      case 'appointmentReminderViaSms':
      case 'googleMapsClinicLinkViaSms':
      case 'medicalHistoryReminderViaSMS':
      case 'postScanNextStepsViaSms':
      case 'postScanPrimaryCareInformation':
      case 'checkinWhereAreYouViaSms':
      case 'reportReleasedViaSmsNotSensitiveWithPrimaryCare':
      case 'reportReleasedViaSmsNotSensitiveWithoutPrimaryCare':
      case 'reportReleasedViaSmsSensitiveWithPrimaryCare':
      case 'reportReleasedViaSmsSensitiveWithoutPrimaryCare':
      case 'feedbackReminderViaSMS':
      case 'sourceMemberThankYouSMS':
        handleSMSDispatch(action);
        break;
      default:
    }
  };

  return (
    <>
      <RemindAppointment
        handleActionDispatch={handleActionDispatch}
        actionsProcessing={actionsProcessing}
        setActionProcessing={setActionProcessing}
        actionsAvailable={actionsAvailable}
        formattedPatient={formattedPatient}
      />
      <CheckinMember
        formattedPatient={formattedPatient}
        handleActionDispatch={handleActionDispatch}
        actionsProcessing={actionsProcessing}
        actionsAvailable={actionsAvailable}
      />
      <HPPBooking />
      <PaymentSection />
      <SalesforceSection
        actionsProcessing={actionsProcessing}
        setActionProcessing={setActionProcessing}
        referringMember={referringMember}
        handleActionDispatch={handleActionDispatch}
        actionsAvailable={actionsAvailable}
      />
      <HealthPractitionerSection />
      <PostReleaseContact
        handleActionDispatch={handleActionDispatch}
        actionsProcessing={actionsProcessing}
        actionsAvailable={actionsAvailable}
      />
      <FeedbackSection
        handleActionDispatch={handleActionDispatch}
        actionsProcessing={actionsProcessing}
        actionsAvailable={actionsAvailable}
      />
      <AmplifyFeedback
        actionsProcessing={actionsProcessing}
        setActionProcessing={setActionProcessing}
      />
    </>
  );
};

export default TaskDetails;
