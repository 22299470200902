import React, { useEffect, useState } from 'react';

import { useCalendlyEventListener, PopupModal } from 'react-calendly';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import {
  Button,
  getConsultType,
  Heading,
  Label,
  Notification,
  ScheduleConsult,
  ScheduledStatus,
  shouldUpdateConsultingScheduledStatus,
  Text,
  ActivityLoader,
  colors,
} from 'shared-library';
import {
  formatUnixDatetime,
  getMoment,
  getMomentFromUnixTimestamp,
  titleCase,
} from 'packages/formatters';
import { getEnvironment } from 'packages/locations';
import { fetchAppsResource, fetchPiiResource } from 'packages/apis';
import styled from 'styled-components';
import { buildPatientSelector } from '../../store/patients/selectors';
import { buildStudySelector } from '../../store/studies/selectors';
import {
  addLogItem,
  updateConsultationRescheduleStatus,
  updateConsultingPractitioner,
} from '../../store/studies/actions';
import { Row, Column } from '../Layout';
import { selectStaff } from '../../store/staff/selectors';

const StyledConsultButtonContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  > * {
    width: max-content;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const LoadingContainer = styled.div`
  background-color: ${colors.black};
  height: 100%;
  width: 100%;
  opacity: 0.2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  background-color: ${colors.white};    
  width: 500px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left 50%;
  transform: translate(-50%, -50%);
`;

const Container = styled.div`
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px;
`;

const ConsultContainer = styled.div`
  padding: 11px;
`;

const BOOKING_MESSAGES = {
  success: {
    loading: false,
    title: 'Booking Confirmed!',
    description: 'Your consultation appointment has been successfully booked.',
    hasError: false,
  },
  loading: {
    loading: true,
    title: 'Processing Your Request...',
    description:
      'We are currently processing your booking request. This may take a moment. Please do not refresh or leave this page until the process is complete.',
    hasError: false,
  },
  failure: {
    loading: false,
    title: 'Booking Issue Detected',
    description:
      'Your booking through Calendly was successful, but we encountered an issue saving it in our system. Please cancel your booked event in Calendly and try submitting your request again.',
    hasError: true,
  },
};

const ScheduleConsultInFrontDesk = ({
  shouldDisplayScheduleConsult,
}: {
  shouldDisplayScheduleConsult: boolean;
}) => {
  const environment = getEnvironment();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { studyId } = useParams();
  const selectStudy = buildStudySelector(studyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));

  const staff = useSelector(selectStaff);
  const findInStaff = (userId: string) => staff.data.find((member) => member.userId === userId);
  const consultTime = study?.practitioners?.consulting?.consultTime || null;
  const [calendlyStartTime, setCalendlyStartTime] = useState<number | null>(consultTime);
  const calendlyStartTimeMoment = consultTime
    ? getMomentFromUnixTimestamp(consultTime).tz(study?.location?.timezone)
    : null;
  const calendlyStartTimezone = calendlyStartTimeMoment ? calendlyStartTimeMoment.zoneAbbr() : null;
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState<boolean>(false);
  const [calendlyUrl, setCalendlyUrl] = useState<string>('');
  const [shouldCheckCalendlyStatus, setShouldCheckCalendlyStatus] = useState(false);
  const [isUserAllowedNPSelection, setIsUserAllowedNPSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingMessage, setBookingMessage] = useState(BOOKING_MESSAGES.loading);

  const consultType = getConsultType(
    study.practitioners?.consulting?.scheduledStatus,
    calendlyStartTime,
  );

  // PRENTECH-3768: restrict access to NP selection to limited set of users
  const userIdsAllowedNPSelection = [
    'auth0|6349db83667e743df0247764', // Larisse B.
    'auth0|5f56f7267a185f006715b68f', // Amanda P.
    'auth0|6516ee349ed3224bd5b84e55', // Lindsay S.
  ];

  const checkCurrentUserCalendlyAccess = async () => {
    const { parsedBody } = await fetchPiiResource('/api/v1/userV2/current');
    const { prenuvoID, roles } = parsedBody.user;
    const regex = /^(qa|dev)$/i;
    const isRoleAllowed = roles.some((string) => regex.test(string));

    if (environment === 'PRODUCTION') {
      setIsUserAllowedNPSelection(userIdsAllowedNPSelection.includes(prenuvoID) || isRoleAllowed);
    } else {
      setIsUserAllowedNPSelection(isRoleAllowed);
    }
  };

  useEffect(() => {
    checkCurrentUserCalendlyAccess();
  }, []);

  const updateConsultReschduleStatusAction = async (isReschduling: boolean) => {
    await updateConsultationRescheduleStatus(studyId, isReschduling)(dispatch);
  };

  const checkAndSetCalendlyUrl = (isSpecial = false) => {
    // PRENTECH-3767: allow specific NPs to be booked for consultation
    const checkAndRemoveTrailingUrl = (url: string, isSpecialConsult: boolean) => {
      if (isSpecialConsult && isUserAllowedNPSelection) {
        const lastIndex = url.lastIndexOf('/');
        if (lastIndex !== -1) {
          return url.substring(0, lastIndex);
        }
      }

      return url;
    };

    const calendlyUrlToSet =
      consultType === 'reschedule'
        ? `https://calendly.com/reschedulings/${study?.practitioners?.consulting?.inviteeID}`
        : checkAndRemoveTrailingUrl(study?.location?.calendlyUrl, isSpecial);
    setCalendlyUrl(calendlyUrlToSet);
    updateConsultReschduleStatusAction(consultType === 'reschedule');
  };

  useEffect(() => {
    if (calendlyModalIsOpen) {
      setCalendlyModalIsOpen(false);
    }
    checkAndSetCalendlyUrl();
  }, [study.practitioners?.consulting?.scheduledStatus, calendlyStartTime]);

  const openCalendlyModal = (isSpecialConsult = false) => {
    checkAndSetCalendlyUrl(isSpecialConsult);

    if (!calendlyUrl) {
      return addToast(
        Notification.create(
          'Error accessing Calendly',
          'This location does not seem to have a valid NP Consult Team on Calendly.',
        ),
        { appearance: 'error' },
      );
    }
    setCalendlyModalIsOpen(true);
  };

  const patientConsultRequestedBy = findInStaff(study.practitioners?.consulting?.requestedBy);

  const patientConsultDoneBy = findInStaff(study.practitioners?.consulting?.prenuvoID);

  const consultEventHandler = async ({ data }) => {
    // Note: consultEventHandler is only triggered when a consult is scheduled the first time
    // and not when using a reschedule link
    setIsLoading(true);
    setBookingMessage(BOOKING_MESSAGES.loading);
    const { event } = data;
    if (event === 'calendly.event_scheduled') {
      const inviteeUri = data?.payload?.invitee?.uri;
      let [eventId, inviteeId] = inviteeUri.split('scheduled_events/').pop().split('/invitees/');

      if (!(eventId && inviteeId)) {
        const eventUri = data?.payload?.event?.uri;
        eventId = eventUri.split('scheduled_events/').pop();
      }
      if (eventId || inviteeId) {
        try {
          setCalendlyModalIsOpen(false);
          await updateConsultingPractitioner(studyId, {
            eventID: eventId,
            inviteeID: inviteeId,
            scheduledStatus: ScheduledStatus.BOOKED,
          })(dispatch);
          setBookingMessage(BOOKING_MESSAGES.success);
        } catch (error) {
          setBookingMessage(BOOKING_MESSAGES.failure);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
            setBookingMessage(BOOKING_MESSAGES.loading);
          }, 4000);
        }
      }
    }
  };

  useCalendlyEventListener({
    onEventScheduled: (e) => consultEventHandler(e),
  });

  const getCalendlyEventDetails = async () => {
    const { parsedBody } = await fetchAppsResource<{ startTime: string; status: string }>(
      `/calendly/${study?.practitioners?.consulting?.eventID}/time`,
    );
    const { startTime, status } = parsedBody;
    if (startTime && getMoment(startTime).isValid()) {
      setCalendlyStartTime(getMoment(startTime).unix());
    }

    if ((status && !study?.consult?.isReschduling) || (status && shouldCheckCalendlyStatus)) {
      const updatedConsultingStatus = shouldUpdateConsultingScheduledStatus(
        status,
        startTime,
        study?.practitioners?.consulting?.scheduledStatus,
      );

      if (updatedConsultingStatus) {
        await updateConsultingPractitioner(studyId, {
          ...study.practitioners.consulting,
          scheduledStatus: updatedConsultingStatus,
        })(dispatch);

        if (updatedConsultingStatus === 'cancelled') {
          addToast(
            Notification.create('Consult cancelled', 'This consultation has been cancelled'),
            { appearance: 'success' },
          );
        }
      }

      if (shouldCheckCalendlyStatus) {
        setShouldCheckCalendlyStatus(false);
      }
    }
  };

  useEffect(() => {
    if (study?.practitioners?.consulting?.eventID) {
      getCalendlyEventDetails();
    }
  }, [study?.practitioners?.consulting?.eventID]);

  const handleReasonForNewConsult = (reason: string) => {
    addLogItem(studyId, `Reason for consultation reschedule: ${reason}`, 'Internal note')(dispatch);
  };

  const onCancelConsult = () => {
    const url = `https://calendly.com/cancellations/${study?.practitioners?.consulting?.inviteeID}`;
    setCalendlyUrl(url);
    setCalendlyModalIsOpen(true);
    setShouldCheckCalendlyStatus(true);
  };

  const onCloseCalendlyModal = async () => {
    await getCalendlyEventDetails();
    setCalendlyModalIsOpen(false);
  };

  const renderConsultRequestedContent = () => {
    switch (study.practitioners?.consulting?.requestedStatus) {
      case 'requested':
        return 'Yes';
      case 'not requested':
        return 'No';
      case 'pending':
        return 'Pending';
      default:
        return 'Unknown';
    }
  };

  return (
    <Container>
      {isLoading && (
        <>
          <LoadingContainer />
          <LoaderWrapper>
            <ActivityLoader
              isLoading={bookingMessage.loading}
              hasError={bookingMessage.hasError}
              headerText={bookingMessage.title}
              bodyText={bookingMessage.description}
            />
          </LoaderWrapper>
        </>
      )}

      <ConsultContainer>
        <Heading size={7}>NP Consult</Heading>
        <Row>
          <Column>
            <Label>Consult requested</Label>
            <Text>{renderConsultRequestedContent()}</Text>
          </Column>
          <Column>
            <Label>Consult requested time</Label>
            <Text>
              {study.practitioners?.consulting?.requestedAt
                ? formatUnixDatetime(
                    study.practitioners?.consulting?.requestedAt,
                    study.location.timezone,
                  )
                : 'N/A'}
            </Text>
          </Column>
          <Column>
            <Label>Consult requested by</Label>
            <Text>
              {patientConsultRequestedBy
                ? `${patientConsultRequestedBy?.firstname} ${patientConsultRequestedBy?.lastname}`
                : 'N/A'}
            </Text>
          </Column>
        </Row>
        {shouldDisplayScheduleConsult && (
          <>
            <Row>
              <Column>
                <Label>Consult booked</Label>
                <Text>
                  {titleCase(study.practitioners?.consulting?.scheduledStatus) || 'Unknown'}
                </Text>
              </Column>
              <Column>
                <Label>Consult booked at</Label>
                <Text>
                  {study.practitioners?.consulting?.scheduledAt
                    ? formatUnixDatetime(
                        study.practitioners?.consulting?.scheduledAt,
                        study.location.timezone,
                      )
                    : 'N/A'}
                </Text>
              </Column>
              <Column>
                <Label>Consult booked for</Label>
                <Text>
                  {calendlyStartTimeMoment
                    ? `${calendlyStartTimeMoment.format(
                        'DD MMM YYYY',
                      )} at ${calendlyStartTimeMoment.format('hh:mma')} ${calendlyStartTimezone}`
                    : 'N/A'}
                </Text>
              </Column>
            </Row>
          </>
        )}
        {study.practitioners?.consulting?.scheduledStatus === ScheduledStatus.COMPLETE && (
          <Row>
            <Text>
              The patient had a consult with {patientConsultDoneBy?.firstname}{' '}
              {patientConsultDoneBy?.lastname} at{' '}
              {getMomentFromUnixTimestamp(
                study.practitioners?.consulting?.consultTime,
                study.location.timezone,
              ).format('DD MMM YYYY')}{' '}
              at{' '}
              {getMomentFromUnixTimestamp(
                study.practitioners?.consulting?.consultTime,
                study.location.timezone,
              ).format('hh:mma')}
              .
            </Text>
          </Row>
        )}
        <StyledConsultButtonContainer>
          <ScheduleConsult
            calendlyStartTime={calendlyStartTime}
            handleReasonForNewConsult={handleReasonForNewConsult}
            openCalendlyModal={openCalendlyModal}
            scheduledStatus={study.practitioners.consulting.scheduledStatus}
          />
          {consultType === 'reschedule' ? (
            <Button onClick={onCancelConsult} variant="secondary" size="medium">
              Cancel consult
            </Button>
          ) : (
            isUserAllowedNPSelection && (
              <Button onClick={() => openCalendlyModal(true)} variant="primary" size="medium">
                Schedule New Preferred Consult
              </Button>
            )
          )}
        </StyledConsultButtonContainer>
        <PopupModal
          url={calendlyUrl}
          utm={{ utmContent: study.studyID }}
          prefill={{
            email: patient.email,
            firstName: patient?.firstname,
            lastName: patient?.lastname,
            name: `${patient?.firstname} ${patient?.lastname}`,
            customAnswers: {
              a1: patient.phoneNumber,
              a2: patient.dob,
            },
          }}
          pageSettings={{
            primaryColor: '003333',
            textColor: '022828',
          }}
          onModalClose={onCloseCalendlyModal}
          open={calendlyModalIsOpen}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById('react-root') || (null as any)}
        />
      </ConsultContainer>
    </Container>
  );
};

export default ScheduleConsultInFrontDesk;
