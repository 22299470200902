import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card, colors, Heading, Section  } from 'shared-library';
import styled from 'styled-components';
import { buildStudySelector } from '../../store/studies/selectors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;

  & > * {
    margin-bottom: 20px;
  }
`;

const HealthPractitionerSection = (): React.ReactElement => {
  const handleTabClickFallBack = () => null;

  const { handleTabClick } = handleTabClickFallBack;
  const { studyId: selectedStudyId } = useParams<{ studyId: string }>();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const history = useHistory();

  const hasPrimaryPractitioner = study.practitioners.primary;

  const handleOnClick = () => {
    history.push({
      ...history.location,
      pathname: `/study/${selectedStudyId}/health-practitioner`,
    });
    handleTabClick('health-practitioner');
  };

  return (
    <Section label="5" title="Add Primary Healthcare Practitioner" viewState="show">
      {hasPrimaryPractitioner ? (
        <Card backgroundColor={colors.smoke}>
          <Heading color={colors.primary} size={5}>
            Completed
          </Heading>
        </Card>
      ) : (
        <Container>
          <Heading size={6}>Incomplete</Heading>
          <Button variant="primary" type="button" size="medium" onClick={handleOnClick}>
            Add a Primary Health Practitioner
          </Button>
        </Container>
      )}
    </Section>
  );
};

export default HealthPractitionerSection;
