import { getMomentFromUnixTimestamp } from 'packages/formatters';
import React from 'react';

import { Section, colors, Text, Card, Heading, Link } from 'shared-library';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { buildStudySelector } from '../../store/studies/selectors';
import { Row } from '../Layout';
import { selectStaff } from '../../store/staff/selectors';

{
  /* eslint-disable jsx-a11y/anchor-is-valid */
}

const PaymentSection = () => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const history = useHistory();
  const staff = useSelector(selectStaff);

  const paidBy = study.status.paidBy
    ? staff.data.filter((member) => member.userId === study.status.paidBy)[0]
    : null;
  const handlePaymentClick = () => {
    history.push({ ...history.location, pathname: `/study/${selectedStudyId}/invoices-payments` });
  };

  return (
    <Section label="3" title="Complete payment" viewState="show">
      {study.status.paid && (
        <Card backgroundColor={colors.smoke}>
          <Heading color={colors.primary} size={5}>
            Completed
          </Heading>
          {paidBy && (
            <Text>
              The study payment was completed by {paidBy?.firstname} {paidBy?.lastname} on on{' '}
              {getMomentFromUnixTimestamp(study.status.paidTime).format('DD MMM YYYY')} at{' '}
              {getMomentFromUnixTimestamp(study.status.paidTime).format('hh:mma')}.
            </Text>
          )}
          {!paidBy && (
            <Text>
              The study payment was completed on{' '}
              {getMomentFromUnixTimestamp(study.status.paidTime).format('DD MMM YYYY')} at{' '}
              {getMomentFromUnixTimestamp(study.status.paidTime).format('hh:mma')}.
            </Text>
          )}
        </Card>
      )}
      {!study.status.paid && (
        <>
          <Row>
            <Text>
              There is a balance owing on this study. Please go to the payment tab to make a
              payment.
            </Text>
          </Row>
          <Row>
            <Link onClick={() => handlePaymentClick()}>Make payment</Link>
          </Row>
        </>
      )}
    </Section>
  );
};

export default PaymentSection;
