import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Section, colors, Button, LoadingSpinner, Text, Card, Heading, Link } from 'shared-library';
import {
  formatCapitalizedFirstLetter,
  formatPhoneNumber,
  getMomentFromUnixTimestamp,
} from 'packages/formatters';

import { Column, Label, Row } from '../Layout';
import { buildStudySelector } from '../../store/studies/selectors';
import { selectStaff } from '../../store/staff/selectors';

{
  /* eslint-disable jsx-a11y/anchor-is-valid */
}

const CheckinMember = ({
  formattedPatient,
  handleActionDispatch,
  actionsProcessing,
  actionsAvailable,
}) => {
  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const staff = useSelector(selectStaff);

  const checkinBy = study.status.checkinBy
    ? staff.data.filter((member) => member.userId === study.status.checkinBy)[0]
    : null;

  return (
    <Section label="2" title="Confirm details and checkin member" viewState="show">
      {!study.status.checkin && (
        <>
          <Heading size={7}>Confirm patient details</Heading>
          <Row>
            <Column>
              <Label>First Name</Label>
              <Text>{formattedPatient?.firstname}</Text>
            </Column>
            <Column>
              <Label>Last Name</Label>
              <Text>{formattedPatient?.lastname}</Text>
            </Column>
            <Column>
              <Label>Date Of Birth</Label>
              <Text>{formattedPatient.dobMoment.format('D MMMM YYYY')}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Address</Label>
              <Text>{formattedPatient.address}</Text>
            </Column>
            <Column>
              <Label>City</Label>
              <Text>{formattedPatient.city}</Text>
            </Column>
            <Column>
              <Label>Zip / Postal Code</Label>
              <Text>{formattedPatient.postalCode}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>State / Province</Label>
              <Text>{formattedPatient.state}</Text>
            </Column>
            <Column>
              <Label>Country</Label>
              <Text>{formattedPatient.country}</Text>
            </Column>
            <Column>
              <Label>Cell Number</Label>
              <Text>
                {formattedPatient.phoneNumber
                  ? formatPhoneNumber(formattedPatient.phoneNumber)
                  : 'N/A'}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Email</Label>
              <Text>{formattedPatient.email}</Text>
            </Column>
            <Column>
              <Label>Biological Sex</Label>
              <Text color={colors.error}>
                {formatCapitalizedFirstLetter(formattedPatient.gender)}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>SMS</Label>
              <div>
                <Link
                  onClick={() => handleActionDispatch('checkinWhereAreYouViaSms')}
                  disabled={actionsProcessing.checkinWhereAreYouViaSms || !actionsAvailable.sms}
                >
                  <>
                    {actionsProcessing.checkinWhereAreYouViaSms && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Late for appointment SMS
                  </>
                </Link>
              </div>
            </Column>
            <Column>
              <Label>Telephone</Label>
              <div>
                <Link
                  onClick={() => {
                    handleActionDispatch('callRingOutPatient');
                  }}
                  disabled={actionsProcessing.reminderRingoutCall }
                >
                  Call member
                </Link>
              </div>
            </Column>
          </Row>
          <Row>
            <Button
              onClick={() => handleActionDispatch('checkin')}
              disabled={actionsProcessing.checkin}
              size="medium"
              variant="primary"
            >
              <>
                {actionsProcessing.checkin && <LoadingSpinner color={colors.white} size="small" />}
                Checkin member
              </>
            </Button>
          </Row>
        </>
      )}
      {study.status.checkin && !study.status.medicalHistory && (
        <>
          <Row>
            <Heading size={7}>Confirm patient details</Heading>
          </Row>
          <Row>
            The patient was checked in on{' '}
            {getMomentFromUnixTimestamp(study.status.checkinTime).format('DD MMM YYYY')} at{' '}
            {getMomentFromUnixTimestamp(study.status.checkinTime).format('hh:mma')}.
          </Row>
          <Row>
            <Column>
              <Label>&nbsp;</Label>
              <Heading size={7}>Complete medical history</Heading>
            </Column>
          </Row>
          <Row>
            This member has not completed their medical history. Please inform them that the reading
            cannot commence until that is completed.
          </Row>
          <Row>
            <Column>
              <Label>SMS</Label>
              <div>
                <Link
                  onClick={() => handleActionDispatch('medicalHistoryReminderViaSMS')}
                  disabled={actionsProcessing.medicalHistoryReminderViaSMS || !actionsAvailable.sms}
                >
                  <>
                    {actionsProcessing.medicalHistoryReminderViaSMS && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Complete medical history
                  </>
                </Link>
              </div>
            </Column>
            <Column>
              <Label>Email</Label>
              <div>
                <Link
                  onClick={() => handleActionDispatch('medicalHistoryReminderViaEmail')}
                  disabled={
                    actionsProcessing.medicalHistoryReminderViaEmail || !actionsAvailable.sms
                  }
                >
                  <>
                    {actionsProcessing.medicalHistoryReminderViaEmail && (
                      <LoadingSpinner color={colors.white} size="small" />
                    )}
                    Complete medical history
                  </>
                </Link>
              </div>
            </Column>
            <Column>
              <Label>Telephone</Label>
              <div>
                <Link
                  onClick={() => {
                    handleActionDispatch('callRingOutPatient');
                  }}
                  disabled={actionsProcessing.reminderRingoutCall }
                >
                  Call member
                </Link>
              </div>
            </Column>
          </Row>
        </>
      )}
      {study.status.medicalHistory && !study.status.checkin && (
        <>
          <Heading size={7}>Confirm patient details</Heading>
          <Row>
            The medical history was completed on{' '}
            {getMomentFromUnixTimestamp(study.status.medicalHistoryTime).format('DD MMM YYYY')} at{' '}
            {getMomentFromUnixTimestamp(study.status.medicalHistoryTime).format('hh:mma')}.
          </Row>
        </>
      )}
      {study.status.checkin && study.status.medicalHistory && (
        <Card backgroundColor={colors.smoke}>
          <Heading color={colors.primary} size={5}>
            Completed
          </Heading>
          <Text>
            {formattedPatient?.firstname} was checked in by {checkinBy?.firstname}{' '}
            {checkinBy?.lastname} on{' '}
            {getMomentFromUnixTimestamp(study.status.checkinTime).format('DD MMM YYYY')} at{' '}
            {getMomentFromUnixTimestamp(study.status.checkinTime).format('hh:mma')}.
          </Text>
        </Card>
      )}
    </Section>
  );
};

export default CheckinMember;
