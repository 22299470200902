import { formatUnixDatetime, getMomentFromUnixTimestamp } from 'packages/formatters';
import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Notification,
  Section,
  colors,
  LoadingSpinner,
  Text,
  Card,
  Heading,
  Link,
  Button,
} from 'shared-library';
import { fetchAppsResource } from 'packages/apis';
import { useToasts } from 'react-toast-notifications';
import { buildStudySelector } from '../../store/studies/selectors';
import { Column, Label, Row } from '../Layout';
import { updateStatus } from '../../store/studies/actions';
import { captureError } from '../../lib';
import { selectStaff } from '../../store/staff/selectors';
import { buildPatientSelector } from '../../store/patients/selectors';
import { selectSession } from '../../store/session/selectors';

const AmplifyFeedback = ({ actionsProcessing, setActionProcessing }) => {
  const dispatch = useDispatch();
  const { data: session } = useSelector(selectSession);

  const { studyId: selectedStudyId } = useParams();
  const selectStudy = buildStudySelector(selectedStudyId);
  const study = useSelector(selectStudy);
  const patient = useSelector(buildPatientSelector(study.patient));

  const { addToast } = useToasts();

  const staff = useSelector(selectStaff);

  const feedbackReceivedNegativeRespondedBy = study.status.feedbackReceivedNegativeRespondedBy
    ? staff.data.filter(
        (member) => member.userId === study.status.feedbackReceivedNegativeRespondedBy,
      )[0]
    : null;
  const feedbackAmplificationDoneBy = study.status.feedbackAmplificationDoneBy
    ? staff.data.filter((member) => member.userId === study.status.feedbackAmplificationDoneBy)[0]
    : null;
  const feedbackAmplificationDeniedBy = study.status.feedbackAmplificationDeniedBy
    ? staff.data.filter((member) => member.userId === study.status.feedbackAmplificationDeniedBy)[0]
    : null;
  const feedbackAmplificationRequestSentBy = study.status.feedbackAmplificationRequestSentBy
    ? staff.data.filter(
        (member) => member.userId === study.status.feedbackAmplificationRequestSentBy,
      )[0]
    : null;

  const handleResendFeedbackAmplificationClick = async () => {
    setActionProcessing({ ...actionsProcessing, feedbackAmplificationRequest: true });
    try {
      const { email } = patient;
      await fetchAppsResource(`/study/${study.studyID}/email/feedback-amplification`);
      addToast(
        Notification.create(
          'Feedback amplification email sent',
          `An email requesting feedback amplification has been sent to ${email}`,
        ),
        { appearance: 'success' },
      );
      setActionProcessing({ ...actionsProcessing, feedbackAmplificationRequest: false });
      await updateStatus(
        study.studyID,
        {
          feedbackAmplificationRequestSent: true,
        },
        session.userId,
      )(dispatch);
    } catch (err) {
      if (err.message !== 'BAD_REQUEST') {
        captureError(err);
      }
      const errorMessage =
        err.message === 'BAD REQUEST'
          ? 'Cannot resend feedback amplification email.'
          : 'Something unexpected went wrong. Give it another try and please report an issue if this persists.';
      addToast(Notification.create('Resend failed', errorMessage), { appearance: 'error' });
      setActionProcessing({ ...actionsProcessing, feedbackAmplificationRequest: false });
    }
  };

  const handleFeedbackNegativeDone = async () => {
    setActionProcessing({ ...actionsProcessing, feedbackNegativeDone: true });
    await updateStatus(
      study.studyID,
      {
        feedbackReceivedNegativeResponded: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, feedbackNegativeDone: false });
  };
  const handleFeedbackAmplificationDone = async () => {
    setActionProcessing({ ...actionsProcessing, feedbackAmplificationDone: true });
    await updateStatus(
      study.studyID,
      {
        feedbackAmplificationDone: true,
        feedbackAmplificationConcluded: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, feedbackAmplificationDone: false });
  };
  const handleFeedbackAmplificationDenied = async () => {
    setActionProcessing({ ...actionsProcessing, feedbackAmplificationDenied: true });
    await updateStatus(
      study.studyID,
      {
        feedbackAmplificationDenied: true,
        feedbackAmplificationConcluded: true,
      },
      session.userId,
    )(dispatch);
    setActionProcessing({ ...actionsProcessing, feedbackAmplificationDenied: false });
  };

  return (
    <Section
      disabled={!study.status.feedbackReceived}
      label="8"
      title="Amplify or handle feedback"
      viewState="show"
    >
      {!study.status.feedbackReceived && (
        <Text>This action is available once feedback has been received by the member.</Text>
      )}
      {study.status.feedbackReceived && (
        <>
          <Row>
            <Text>Feedback received {formatUnixDatetime(study.status.feedbackReceivedTime)}.</Text>
          </Row>
          <Row>
            <Column>
              <Label>Cultivation/purchase</Label>
              <Text>
                {study.feedback.prescan ? study.feedback.prescan.replace(/:star:/g, '★') : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Checkin</Label>
              <Text>
                {study.feedback.checkin ? study.feedback.checkin.replace(/:star:/g, '★') : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Mri scan</Label>
              <Text>{study.feedback.mri ? study.feedback.mri.replace(/:star:/g, '★') : 'N/A'}</Text>
            </Column>
          </Row>
          <Row>
            <Column>
              <Label>Radiology</Label>
              <Text>
                {study.feedback.radiology
                  ? study.feedback.radiology.replace(/:star:/g, '★')
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Overall experience</Label>
              <Text>
                {study.feedback.experience
                  ? study.feedback.experience.replace(/:star:/g, '★')
                  : 'N/A'}
              </Text>
            </Column>
            <Column>
              <Label>Would recommend</Label>
              <Text>
                {study.feedback.recommend
                  ? study.feedback.recommend.replace(/:star:/g, '★')
                  : 'N/A'}
              </Text>
            </Column>
          </Row>
          <Row>
            <Label>Feedback</Label>
          </Row>
          <Row>
            <Text>{study.feedback.feedback}</Text>
          </Row>
        </>
      )}
      {study.status.feedbackReceivedNegative && (
        <>
          <Text>This member had negative feedback and they must be contacted to resolve.</Text>
          {!study.status.feedbackReceivedNegativeResponded && (
            <>
              <Button
                variant="primary"
                onClick={handleFeedbackNegativeDone}
                disabled={actionsProcessing.feedbackNegativeDone}
                size="medium"
              >
                {actionsProcessing.feedbackNegativeDone ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  `Response to negative feedback done`
                )}
              </Button>
            </>
          )}
          {study.status.feedbackReceivedNegativeResponded && (
            <Card backgroundColor={colors.smoke}>
              <Heading color={colors.primary} size={5}>
                Completed
              </Heading>
              <Text>
                The member&apos;s negative feedback has been marked as resolved by{' '}
                {feedbackReceivedNegativeRespondedBy?.firstname}{' '}
                {feedbackReceivedNegativeRespondedBy?.lastname} on{' '}
                {getMomentFromUnixTimestamp(
                  study.status.feedbackReceivedNegativeRespondedTime,
                ).format('DD MMM YYYY')}{' '}
                at{' '}
                {getMomentFromUnixTimestamp(
                  study.status.feedbackReceivedNegativeRespondedTime,
                ).format('hh:mma')}
                .
              </Text>
            </Card>
          )}
        </>
      )}
      {study.status.feedbackReceived && study.status.feedbackAmplificationDone && (
        <Card backgroundColor={colors.smoke}>
          <Heading color={colors.primary} size={5}>
            Completed
          </Heading>
          <Text>
            The member was marked as having amplified their feedback by{' '}
            {feedbackAmplificationDoneBy?.firstname} {feedbackAmplificationDoneBy?.lastname} on{' '}
            {getMomentFromUnixTimestamp(study.status.feedbackAmplificationDoneTime).format(
              'DD MMM YYYY',
            )}{' '}
            at{' '}
            {getMomentFromUnixTimestamp(study.status.feedbackAmplificationDoneTime).format(
              'hh:mma',
            )}
            .
          </Text>
        </Card>
      )}

      {study.status.feedbackReceived && study.status.feedbackAmplificationDenied && (
        <Card backgroundColor={colors.smoke}>
          <Heading color={colors.primary} size={5}>
            Completed
          </Heading>
          <Text>
            The member was marked as not wanting to amplify their feedback by{' '}
            {feedbackAmplificationDeniedBy?.firstname} {feedbackAmplificationDeniedBy?.lastname} on{' '}
            {getMomentFromUnixTimestamp(study.status.feedbackAmplificationDeniedTime).format(
              'DD MMM YYYY',
            )}{' '}
            at{' '}
            {getMomentFromUnixTimestamp(study.status.feedbackAmplificationDeniedTime).format(
              'hh:mma',
            )}
            .
          </Text>
        </Card>
      )}
      {study.status.feedbackReceived &&
        !study.status.feedbackAmplificationDone &&
        !study.status.feedbackAmplificationDenied && (
          <>
            {study.status.feedbackAmplificationRequestSentTime && (
              <Text>
                Last feedback amplification requested communication sent by{' '}
                {feedbackAmplificationRequestSentBy?.firstname}{' '}
                {feedbackAmplificationRequestSentBy?.lastname} on{' '}
                {getMomentFromUnixTimestamp(
                  study.status.feedbackAmplificationRequestSentTime,
                ).format('DD MMM YYYY')}{' '}
                at{' '}
                {getMomentFromUnixTimestamp(
                  study.status.feedbackAmplificationRequestSentTime,
                ).format('hh:mma')}
                .
              </Text>
            )}
            {!study.status.feedbackAmplificationRequestSentTime && (
              <Text>Feedback reminder never requested.</Text>
            )}
            <Link
              onClick={() => handleResendFeedbackAmplificationClick()}
              disabled={actionsProcessing.feedbackAmplificationRequest}
            >
              Send Feedback Amplification Email
            </Link>
            <div>
              <Text>&nbsp;</Text>
              <Button
                variant="primary"
                onClick={handleFeedbackAmplificationDone}
                disabled={actionsProcessing.feedbackAmplificationDone}
                size="medium"
              >
                {actionsProcessing.feedbackAmplificationDone ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  `Feedback amplification done by member`
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={handleFeedbackAmplificationDenied}
                disabled={actionsProcessing.feedbackAmplificationDenied}
                size="medium"
              >
                {actionsProcessing.feedbackAmplificationDenied ? (
                  <LoadingSpinner color={colors.white} size="small" />
                ) : (
                  `Member won't do feedback amplification (or feedback is poor)`
                )}
              </Button>
            </div>
          </>
        )}
    </Section>
  );
};

export default AmplifyFeedback;
