import React, { useState } from 'react';
import {
  Button as SLButton,
  TextInput,
  Modal,
  Text,
  LoadingSpinner,
  Notification,
} from 'shared-library';
import { fetchPiiResource } from 'packages/apis';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { Row } from '../Layout';
import StudyIdMismatchModal from './StudyIdMismatchModal';
import SalesforceTimeout, { Div, Button } from './SalesforceTimeout';
import { updateOpportunityIdOnStudy } from '../../store/studies/actions';

const UpdateButton = styled(SLButton)`
  margin-top: 20px;
  margin-left: 12px;
`;

const SalesforceTextInput: React.FC = () => {
  const [opportunityId, setOpportunityId] = useState('');
  const [updating, setUpdating] = useState(false);
  const [studyIdMismatch, setStudyIdMismatch] = useState(false);
  const [salesforceTimeout, setSalesforceTimeout] = useState(false);
  const [noOpportunity, setNoOpportunity] = useState(false);

  const { studyId: selectedStudyId } = useParams();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const handleSalesforceTextInput = async () => {
    setUpdating(true);
    if (salesforceTimeout) {
      setSalesforceTimeout(false);
    }
    try {
      const { parsedBody } = await fetchPiiResource(
        `/api/v1/salesforce/opportunity/${opportunityId}`,
      );
      if (!parsedBody) {
        setNoOpportunity(true);
      } else {
        const { Study_ID__c: studyId } = parsedBody;

        if (studyId === selectedStudyId) {
          await updateOpportunityIdOnStudy(studyId, parsedBody)(dispatch);
          addToast(
            Notification.create(
              'Salesforce Opportunity Update',
              'Successfully updated the opportunity on the study',
            ),
            { appearance: 'success' },
          );
        } else {
          setStudyIdMismatch(studyId || true);
        }
      }
    } catch (e) {
      setSalesforceTimeout(true);
    }
    setUpdating(false);
  };
  return (
    <>
      {studyIdMismatch && (
        <StudyIdMismatchModal
          opportunityId={opportunityId}
          setStudyIdMismatch={setStudyIdMismatch}
        />
      )}
      {salesforceTimeout && (
        <SalesforceTimeout
          handleSalesforceTextInput={handleSalesforceTextInput}
          setSalesforceTimeout={setSalesforceTimeout}
        />
      )}
      {noOpportunity && (
        <Modal onClose={() => setNoOpportunity(false)}>
          <Text align="center">
            No opportunity found for the entered value. Please confirm that this is the correct ID
            for the OPPORTUNITY, and not the PERSON on Salesforce.
          </Text>
          <Div>
            <Button onClick={() => setNoOpportunity(false)}>Close</Button>
          </Div>
        </Modal>
      )}
      <Row>
        <TextInput
          label="Update Salesforce Opportunity ID"
          onChange={setOpportunityId}
          defaultValue={opportunityId}
        />
        <UpdateButton onClick={handleSalesforceTextInput} disabled={updating} variant="primary">
          {updating ? <LoadingSpinner /> : 'Update'}
        </UpdateButton>
      </Row>
    </>
  );
};

export default SalesforceTextInput;
